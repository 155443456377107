import { GetAllGoodsCategorys } from "@/api/GoodsApi";
import { reactive } from "vue";
/**
 * 商品分类服务
 */
export default class CategoryService {
    static async InitAllCatagory() {
        if (this.allCatagory.length == 0) {
            const result = (await GetAllGoodsCategorys());
            this.allCatagory = reactive(result.resultData);
        }
        return this.allCatagory;
    }
    static GetAllCatagory() {
        return this.allCatagory;
    }
    static GetCatagoryNameById(id) {
        if (!id) {
            return "";
        }
        const allCatagory = this.allCatagory;
        for (let i = 0; i < allCatagory.length; i++) {
            if (allCatagory[i].id == id) {
                return allCatagory[i].name;
            }
            if (allCatagory[i].children && allCatagory[i].children.length > 0) {
                const cata = this.deepFind(allCatagory[i].children, id);
                if (cata) {
                    return allCatagory[i].name + "-" + cata;
                }
            }
        }
        return "";
    }
    static GetParentNodes(id) {
        const result = new Array();
        let hasFind = false;
        this.allCatagory.forEach(i => {
            if (hasFind) {
                return false;
            }
            if (i.id == id) {
                hasFind = true;
                result.push(i.id);
                return false;
            }
            i.children.forEach(j => {
                if (hasFind) {
                    return false;
                }
                if (j.id == id) {
                    hasFind = true;
                    result.push(i.id);
                    result.push(j.id);
                    return false;
                }
                j.children.forEach(x => {
                    if (hasFind) {
                        return false;
                    }
                    if (x.id == id) {
                        hasFind = true;
                        result.push(i.id);
                        result.push(j.id);
                        result.push(x.id);
                        return false;
                    }
                });
            });
        });
        return result;
    }
    static deepFind(source, id) {
        for (let i = 0; i < source.length; i++) {
            if (source[i].id == id) {
                return source[i].name;
            }
            const tempName = source[i].name;
            if (source[i].children && source[i].children.length > 0) {
                const cate = this.deepFind(source[i].children, id);
                if (cate)
                    return tempName + "-" + cate;
            }
        }
        return "";
    }
}
CategoryService.allCatagory = reactive(new Array());
