import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import '@/commonStyle/main.scss';
import '@wangeditor/editor/dist/css/style.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
const jquery = require('jquery');
window.jQuery = jquery;
window.$ = jquery;
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.use(VueAxios, axios);
app.use(router);
app.use(ElementPlus, { locale });
app.mount('#app');
