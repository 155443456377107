import { GetChannelList } from "@/api/GoShopApi";
import { reactive } from "@vue/runtime-dom";
import ShopService from "../Shop/ShopService";
export default class ChannelService {
    static InitChannelList() {
        const list = ShopService.GetCurrentShop().storeChannelList;
        ChannelService.refreshChannel(list);
    }
    static GetChannelList() {
        return this.channelList;
    }
    static async UpdateChannelList(data) {
        const result = await GetChannelList(data);
        this.refreshChannel(result.resultData.list);
    }
    static refreshChannel(list) {
        this.channelList.splice(0, this.channelList.length);
        list?.forEach(item => {
            this.channelList.push(item);
        });
    }
}
ChannelService.channelList = reactive(new Array());
