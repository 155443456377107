import { Get, Post } from "./Request";
class MessageConStr {
}
class MessageContentObj {
}
class MessageContent {
}
export class MessageList {
}
export class Message {
}
// 全部消息列表
export function AllMessageList(data) {
    return Get(`/message/allMessageList?pageNum=${data.pageNum}&pageSize=${data.pageSize}&storeId=${data.storeId}`);
}
// 消息详情
export function MessageDetail(data) {
    return Post('/message/messageInfo', data);
}
// 消息标记为已读
export function MessageRead(data) {
    return Get(`/message/messageMarkRead?messageId=${data.messageId}&storeId=${data.storeId}`);
}
// 未读消息数量
export function MessageNum(storeId) {
    return Get(`/message/messageNum?storeId=${storeId}`);
}
// 未读消息列表
export function UnreadMessageList(storeId) {
    return Get(`/message/unreadList?storeId=${storeId}`);
}
// 重要未读消息列表
export function UnreadImportMessageList(storeId) {
    return Get(`/message/unreadRemindMessageList?storeId=${storeId}`);
}
