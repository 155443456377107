import Layout from 'views/Layout.vue';
export default {
    path: '/afterSale',
    name: 'afterSale',
    component: Layout,
    children: [
        {
            path: '',
            name: 'afterSaleList',
            component: () => import('views/AfterSale/AfterSale.vue')
        },
    ]
};
