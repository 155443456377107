import Layout from 'views/Layout.vue';
export default {
    path: '/delivery',
    name: 'delivery',
    component: Layout,
    children: [
        {
            path: '',
            name: 'deliveryList',
            component: () => import('views/Delivery/Delivery.vue')
        },
    ]
};
