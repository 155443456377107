import { SkuEnableStatus } from "@/common/enum";
import SkuServices from "./SkuServices";
export class SkuItem {
    constructor() {
        this.id = "";
        this.productSn = "";
        // 库存
        this.stock = 0;
        // 成本价
        this.price = 0;
        // 启用状态 1.正常启用 2.未被启用
        this.enableStatus = SkuEnableStatus.Normal;
        // 市场价
        this.originalPrice = 0;
        // 自营价
        this.selfSalePrice = 0;
        // 平台成本价
        this.platformPricingPrice = 0;
        // 平台销售价
        this.platformPricingSalePrice = 0;
        // 佣金
        this.platformPricingCommission = 0;
        // 规格id组合
        this.specContentIds = "";
        // 规格内容
        this.specList = new Array();
        // 重量
        this.weight = 0;
        this.id = SkuServices.GetRandomSkuId();
    }
}
