import { PlatPost, PlatGet } from './Request';
// 请求物流列表时候的参数
export class GetDeliverArgs {
}
// 物流列表包含的字段
export class DeliverResponse {
}
// 查询发货单列表
export function GetDeliverList(data) {
    return PlatPost("/backend/shop/deliver/list", data);
}
// 编辑物流信息
export function EditDeliver(data) {
    return PlatPost("/backend/shop/deliver/editExpress", data);
}
// 添加物流信息
export function AddDeliver(data) {
    return PlatPost('/backend/shop/deliver/addExpress', data);
}
// 获取未处理的数量
export function getDeliveryCount() {
    return PlatGet('/backend/shop/deliver/handleNum');
}
// 备注信息
export function editRemarks(data) {
    return PlatPost("/backend/shop/deliver/editRemark", data);
}
// 获取订单发货信息
export function getOrderDeliver(orderId) {
    return PlatGet(`/backend/shop/deliver/getDeliverInfo/${orderId}`);
}
// 修改处理说明
export function updateDeliverRemark(data) {
    return PlatPost("/backend/shop/deliver/editDeliverRemark", data);
}
