import { ElMessage } from "element-plus";
import { reactive } from "vue";
import SkuServices from "../Skus/SkuServices";
export default class SpecService {
    static SpecCheck() {
        const previewSkuCount = this.GetPreviewSkusCount();
        if (previewSkuCount > SkuServices.MaxSkuCount) {
            ElMessage.error(`规格数量计算出的SKU数量不能超过${SkuServices.MaxSkuCount}条`);
            return false;
        }
        return true;
    }
    static AddSpecs(item) {
        this.specs.push(item);
    }
    static RemoveSpecs(index) {
        this.specs.splice(index, 1);
        this.specs.forEach((i, idx) => {
            i.type = idx + 1;
        });
    }
    static UpdatePics(specIndex, contentIndex, pics) {
        this.specs[specIndex].contentList[contentIndex].pics = pics;
    }
    static AddSpecContent(specIndex, item) {
        this.specs[specIndex].contentList.push(item);
    }
    static Replace(index, item) {
        this.specs.splice(index, 1, item);
        this.SpecsValid();
    }
    static GetRandomSpecId() {
        return "NS" + (new Date().getTime());
    }
    static GetRandomContentId() {
        return "NC" + (new Date().getTime());
    }
    static GetSpecs() {
        return this.specs;
    }
    static InitSpecsList(list) {
        this.specs.splice(0, this.specs.length);
        list.forEach(x => {
            this.specs.push(x);
        });
    }
    static ClearSpecs() {
        this.specs.splice(0, this.specs.length);
    }
    static GetPreviewSkusCount() {
        let result = 1;
        for (let i = 0; i < this.specs.length; i++) {
            if (this.specs[i].contentList.length > 0) {
                result = result * this.specs[i].contentList.length;
            }
        }
        return result;
    }
    static SpecsValid() {
        this.errors.splice(0, this.errors.length);
        const tempNames = new Array();
        let result = true;
        for (let i = 0; i < this.specs.length; i++) {
            if (this.specs[i].name.length <= 0) {
                this.errors.push({ id: this.specs[i].id, key: "name" });
                result = false;
            }
            if (tempNames.indexOf(this.specs[i].name) >= 0) {
                this.errors.push({ id: this.specs[i].id, key: "name" });
                // ElMessage.error(`规格【${i + 1}】和【${tempNames.indexOf(this.specs[i].name) + 1}】名称重复`);
                result = false;
            }
            if (this.SpecChars.test(this.specs[i].name)) {
                this.errors.push({ id: this.specs[i].id, key: "name" });
                result = false;
            }
            tempNames.push(this.specs[i].name);
            const tempContents = new Array();
            let hasImage = false;
            for (let j = 0; j < this.specs[i].contentList.length; j++) {
                if (this.specs[i].type == 1 && this.specs[i].contentList[j].pics.length > 0) {
                    hasImage = true;
                    break;
                }
            }
            for (let j = 0; j < this.specs[i].contentList.length; j++) {
                if (this.specs[i].contentList[j].content.length <= 0) {
                    this.errors.push({ id: this.specs[i].contentList[j].id, key: "content" });
                    // ElMessage.error(`规格名称为【${this.specs[i].name}】的第【${j + 1}】个规格内容不能为空`)
                    result = false;
                }
                if (this.SpecChars.test(this.specs[i].contentList[j].content)) {
                    this.errors.push({ id: this.specs[i].contentList[j].id, key: "content" });
                    // ElMessage.error(`规格名称为【${this.specs[i].name}】的第【${j + 1}】个规格内容不能有特殊字符`)
                    result = false;
                }
                if (hasImage) {
                    if (this.specs[i].type == 1 && this.specs[i].contentList[j].pics.length <= 0) {
                        this.errors.push({ id: this.specs[i].contentList[j].id, key: "pics" });
                        // ElMessage.error(`规格名称为【${this.specs[i].name}】的第【${j + 1}】个规格内容是【${this.specs[i].contentList[j].content || ""}】规格图片不能为空`)
                        result = false;
                    }
                }
                if (tempContents.indexOf(this.specs[i].contentList[j].content) >= 0) {
                    this.errors.push({ id: this.specs[i].contentList[j].id, key: "content" });
                    // ElMessage.error(`规格名称为【${this.specs[i].name}】的规格内容：【${j + 1}】和【${tempContents.indexOf(this.specs[i].contentList[j].content) + 1}】重复`);
                    result = false;
                }
                tempContents.push(this.specs[i].contentList[j].content);
            }
        }
        return result;
    }
}
SpecService.specs = reactive(new Array());
SpecService.MaxSpecCount = 3;
SpecService.MaxSpecContentCount = 40;
SpecService.SpecChars = RegExp(/;|:|；|：|，|,|，/);
// 放置校验错误的信息
SpecService.errors = reactive(new Array());
