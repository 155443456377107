import Layout from 'views/Layout.vue';
export default {
    path: '/shopAfterSale',
    name: 'shopAfterSale',
    component: Layout,
    children: [
        {
            path: '',
            name: 'shopAfterSaleList',
            component: () => import('views/ShopManagement/AfterSale/AfterSale.vue')
        },
    ]
};
