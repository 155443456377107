import AccountService from "@/services/Account/AccountService";
import { Del, Get, Post } from "./Request";
export class ShopProduction {
}
export class ShopSectionProduction {
}
// 店铺列表 
export function GetGoShopList(data) {
    return Get(`/store/list?name=${data.name || ''}&pageNum=${data.pageNum}&pageSize=${data.pageSize}&storeNo=${data.storeNo || ''}&merchantId=${AccountService.GetLoginInfo()?.merchantId}`);
}
// 店铺提交审核
export function ShopSubmit(data) {
    return Post("/store/submit", data);
}
// 撤销审核申请
export function CanCelAudit(data) {
    return Get(`/store/cancelApplication?id=${data.id}`);
}
// 停止营业 
export function StopBusiness(data) {
    return Get(`/store/closeStore?id=${data.id}`);
}
// 重新营业 
export function ReOpen(data) {
    return Get(`/store/reopenStore?id=${data.id}`);
}
// 放弃修改 
export function GiveUpChange(data) {
    return Get(`/store/discardChange?id=${data.id}`);
}
// 店铺详情 /store/{id}
export function GoShopDetail(data) {
    return Get(`/store/${data.id}`);
}
// 查询店铺名称是否可用 
export function ConfirmGOShopName(name) {
    return Get(`/store/confirmStoreName?name=${name}`);
}
// 查询店铺标识是否可用
export function ConfirmGOShopNo(store_no) {
    return Get(`/store/confirmStoreNo?store_no=${store_no}`);
}
// 获取广告图 
export function GetAdvertBanner(data) {
    return Get(`/banner/list?merchantStoreId=${data.id}&picType=${data.picType}&pageNum=1&pageSize=100`);
}
// 添加广告图 
export function AddAdvertBanner(data) {
    return Post(`/banner/create`, data);
}
// 删除广告图 /banner/delete/{id}
export function DelAdvertBanner(data) {
    return Del(`/banner/delete/${data.id}`);
}
// 修改广告图 
export function EditAdvertBanner(data) {
    return Post(`/banner/updateById`, data);
}
// 广告图排序 /banner/resort
export function sortAdvertBanner(data) {
    return Post(`/banner/resort`, data);
}
// 获取渠道 /channel/{id}
export function GetChannelList(data) {
    return Get(`/channel/list?merchantStoreId=${data.id}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`, data);
}
// 添加渠道 
export function AddChannelList(data) {
    return Post(`/channel/create`, data);
}
// 删除渠道 
export function DelChannelList(data) {
    return Del(`/channel/delete/${data.id}`);
}
// 重命名频道
export function RenameSection(data) {
    return Post("/section/rename", data);
}
// 排序频道
export function SortSection(data) {
    return Post("/section/resort", data);
}
// 店铺添加频道
export function ShopAddSection(data) {
    return Post("/section/add", data);
}
// 店铺删除频道
export function ShopDelSection(data) {
    return Del("/section/delete/" + data.id);
}
//批量添加频道
export function BatchAddSection(data) {
    return Post("/sectionSelectionRelation/batchAddByProductNo", data);
}
//批量移除频道
export function BatchDelSection(data) {
    return Post("/sectionSelectionRelation/batchRemoveByProductNo", data);
}
// 获取分销商品列表
export function GetShareProList(data) {
    return Get(`/storeProductChoose/shareList?pageNum=${data.pageNum}&pageSize=${data.pageSize}&name=${data.name}&productCategoryId=${data.productCategoryId}&sortType=${data.sortType}`);
}
// 获取自营商品列表
export function GetSelfProList(data) {
    return Get(`/storeProductChoose/selfList?pageNum=${data.pageNum}&pageSize=${data.pageSize}&name=${data.name}&productCategoryId=${data.productCategoryId}&sortType=${data.sortType}`);
}
// 获取频道列表
export function GetSectionList(data) {
    return Get(`/section/list?merchantStoreId=${data.merchantStoreId}`);
}
// 店铺添加选品
export function ShopAddProduct(data) {
    return Post("/sectionSelectionRelation/addByProductNo", data);
}
// 根据商品编号查已选频道信息
export function GetSectionByProductNo(data) {
    return Post("/sectionSelectionRelation/sectionInfosByProductNo", data);
}
// 取消选品
export function DelShopProduct(data) {
    return Del("/sectionSelectionRelation/revoke/" + data.id);
}
// 调整店铺商品销售状态
export function ChangeShopProdSaleStatus(data) {
    return Post("/storeProductSelection/changeSaleStatus", data);
}
// 获取可用的店铺列表
export function GetAvalibleShopList(data) {
    return Get("/store/availableStoreList");
}
// 调整商品排序权重
export function ChangeSortWeight(data) {
    return Post("/storeProductSelection/changeSortWeight", data);
}
// 根据商品编码关联频道
export function AddShopSectionRelation(data) {
    return Post("/sectionSelectionRelation/addByProductNo", data);
}
// 取消商品频道关联
export function DelShopSectionRelation(id) {
    return Del(`/sectionSelectionRelation/delete/${id}`);
}
// 更新店铺登录时间
export function UpdateLoginTime(storeId) {
    return Get(`/store/updateLoginTime?storeId=${storeId}`);
}
// 新版选品但不上架
export function AddByProductNoAndDown(data) {
    return Post("/storeProductSelection/addByProductNoAndDown", data);
}
// 新版选品并上架
export function AddByProductNoAndUp(data) {
    return Post("/storeProductSelection/addByProductNoAndUp", data);
}
export class OrderResponse {
}
