export default class CookieService {
    static SetCookie(key, value, expireTime) {
        const now = new Date();
        now.setTime(now.getTime() + (expireTime ? expireTime : this.expires));
        document.cookie =
            key + "=" + escape(JSON.stringify(value))
                + ";expires=" + now.toUTCString() + ";";
    }
    static HasKey(key) {
        const name = key + "=";
        const ca = document.cookie.split(';');
        return ca.indexOf(name) >= 0;
    }
    static GetCookie(key) {
        const name = key + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i].trim();
            if (c.indexOf(name) == 0) {
                const result = c.substring(name.length, c.length);
                if (result == "undefined") {
                    return null;
                }
                return JSON.parse(unescape(result));
            }
        }
        return null;
    }
    static RemoveCookie(key) {
        document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
/**
 * 过期毫秒数，默认为一年
 */
CookieService.expires = 3600000 * 24 * 365;
