import { CountService } from "@/services/Logistic/CountService";
import { defineComponent, onMounted, ref, watch } from "vue";
import NavBar from "@/components/navbar/Index.vue";
import router from "@/router";
import MerchantService from "@/services/Merchant/MerchantService";
import { getZhikeToken } from "@/api/GoodsApi";
import { WindowOpen } from "@/common/utils";
export default defineComponent({
    components: {
        NavBar,
    },
    name: "Aside",
    data() {
        const menus = [
            {
                id: "1",
                url: "/home",
                activeIcon: require("@/images/active_shop.png"),
                icon: require("@/images/shop.png"),
                title: "首页",
                children: null,
            },
            {
                id: "2",
                activeIcon: require("@/images/active_goods.png"),
                icon: require("@/images/goods.png"),
                title: "店铺管理",
                children: [
                    {
                        id: "2-0",
                        url: "/shop/info",
                        title: "基本信息",
                    },
                    {
                        id: "2-1",
                        url: "/shop/section",
                        title: "频道配置",
                    },
                    {
                        id: "2-2",
                        url: "/shop/advert",
                        title: "广告位配置",
                    },
                    {
                        id: "2-3",
                        url: "/shop/channel",
                        title: "渠道配置",
                    },
                ],
            },
            {
                id: "3",
                activeIcon: require("@/images/active_goods.png"),
                icon: require("@/images/goods.png"),
                title: "商品管理",
                children: [
                    {
                        id: "3-0",
                        url: "/goods/add",
                        title: "添加商品",
                    },
                    {
                        id: "3-1",
                        url: "/goods/wait",
                        title: "待入库商品",
                    },
                    {
                        id: "3-2",
                        url: "/goods/select",
                        title: "分销市场选货",
                    },
                    {
                        id: "3-3",
                        url: "/goods",
                        title: "商品列表",
                    },
                    {
                        id: "3-4",
                        url: "/goods/import",
                        title: "导入商品",
                    },
                ],
            },
            {
                id: "4",
                url: "/orders",
                activeIcon: require("@/images/active_shop.png"),
                icon: require("@/images/shop.png"),
                title: "店铺订单",
                children: null,
            },
            {
                id: "14",
                url: "/shopAfterSale",
                activeIcon: require("@/images/active_shop.png"),
                icon: require("@/images/shop.png"),
                title: "店铺售后单",
                children: null,
            },
            {
                id: "5",
                url: "/delivery",
                activeIcon: require("@/images/active_logistic.png"),
                icon: require("@/images/logistic.png"),
                title: "发货处理",
                children: null,
                count: CountService.WaitCount,
            },
            {
                id: "6",
                url: "/afterSale",
                activeIcon: require("@/images/active_shop.png"),
                icon: require("@/images/shop.png"),
                title: "售后处理",
                children: null,
                count: CountService.AfterSaleWait,
            },
            {
                id: "7",
                activeIcon: require("@/images/active_goods.png"),
                icon: require("@/images/goods.png"),
                title: "营销活动",
                children: [
                    {
                        id: "7-0",
                        url: "/marketing/coupon",
                        title: "优惠券",
                    },
                    {
                        id: "7-1",
                        url: "/marketing/envelope",
                        title: "红包",
                    },
                ],
            },
            // {
            //   id: "8",
            //   activeIcon: require("@/images/active_goods.png"),
            //   icon: require("@/images/goods.png"),
            //   title: "店铺数据",
            //   children: [
            //     {
            //       id: "8-0",
            //       url: "/goods/add",
            //       title: "订单转化统计",
            //     },
            //     {
            //       id: "8-1",
            //       url: "/goods",
            //       title: "销量统计",
            //     },
            //     {
            //       id: "8-2",
            //       url: "/goods/wait",
            //       title: "店铺账单",
            //     },
            //     {
            //       id: "8-3",
            //       url: "/goods/wait",
            //       title: "供货账单",
            //     },
            //   ],
            // },
            // {
            //   id: "9",
            //   activeIcon: require("@/images/active_goods.png"),
            //   icon: require("@/images/goods.png"),
            //   title: "发票管理",
            //   children: null,
            // },
            {
                id: "10",
                activeIcon: require("@/images/active_goods.png"),
                icon: require("@/images/goods.png"),
                title: "设置",
                children: [
                    {
                        id: "10-0",
                        url: "/settings/orderInfo",
                        title: "下单模板配置",
                    },
                ],
            },
        ];
        let activeRoute = ref("1");
        const activeId = ref("1");
        const linkStatus = ref("el-icon-headset");
        onMounted(() => {
            let menuId = getSelectRoute(menus);
            activeRoute.value = menuId;
            activeId.value = menuId;
        });
        const getSelectRoute = (items) => {
            const route = window.location.hash.replace("#", "");
            for (let i = 0; i < items.length; i++) {
                if (route.split("?")[0] == items[i].url) {
                    return items[i].id;
                }
                if (items[i].children) {
                    let selectMenu = getSelectRoute(items[i].children);
                    if (selectMenu) {
                        return selectMenu;
                    }
                }
            }
            return "";
        };
        const onSelected = (item) => {
            activeId.value = item;
        };
        watch(() => router.currentRoute.value.fullPath, (newVal, oldVal) => {
            for (let i = 0; i < menus.length; i++) {
                if (menus[i].url == newVal.split("?")[0]) {
                    activeRoute.value = menus[i].id;
                    break;
                }
                findRoute(menus[i], newVal);
            }
        });
        const findRoute = (menuItems, toUrl) => {
            if (menuItems.children && menuItems.children.length > 0) {
                for (let i = 0; i < menuItems.children.length; i++) {
                    if (menuItems.children[i].url == toUrl) {
                        activeRoute.value = menuItems.children[i].id;
                        break;
                    }
                }
            }
        };
        const toZhiKe = () => {
            linkStatus.value = "el-icon-loading";
            getZhikeToken()
                .then((res) => {
                linkStatus.value = "el-icon-headset";
                if (res.isSuccess) {
                    const url = `${process.env.VUE_APP_ZHIKE_URL}?access_token=${res.resultData.access_token}&token_type=${res.resultData.token_type}&user_type=${res.resultData.user_type}`;
                    WindowOpen(url);
                }
            })
                .catch(() => {
                linkStatus.value = "el-icon-headset";
            });
        };
        return {
            MerchantService,
            menus,
            activeRoute,
            activeId,
            onSelected,
            toZhiKe,
            linkStatus,
        };
    },
});
