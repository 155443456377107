import { MerchantSource } from '@/common/enum';
import AccountService from '@/services/Account/AccountService';
import ShopService from '@/services/Shop/ShopService';
import CookieService from '@/services/Store/CooikeService';
import axios from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import fileDownload from 'js-file-download';
const baseUrl = process.env.VUE_APP_BASE_API;
const ReLoginCode = 'A3000';
const ShopNoMatchCode = 'A3001';
export class LoginInfo {
    constructor() {
        this.token = '';
        this.merchantId = 0;
        this.storeId = 0;
        this.merchantName = '';
        this.qid = '';
        this.merchantSource = MerchantSource.Normal;
    }
}
export function GetAuthToken() {
    const loginInfo = CookieService.GetCookie('loginInfo');
    const token = loginInfo?.token;
    return token;
}
function request(url, method, data, hideWarning, headersConfig) {
    const token = GetAuthToken();
    const loginInfo = CookieService.GetCookie('loginInfo');
    return new Promise((relove, reject) => {
        axios({
            url: url,
            data: data,
            method: method,
            headers: {
                Authorization: token,
                authStore: ShopService.GetCurrentShop().id,
                ...headersConfig
            },
        })
            .then((result) => {
            if (result.data.resultCode == ReLoginCode) {
                AccountService.Logout();
                return;
            }
            if (result.data.resultCode == ShopNoMatchCode) {
                AccountService.Logout();
                return;
            }
            if (result.data.isSuccess) {
                relove(result.data);
            }
            else {
                !hideWarning && ElMessage.error(result.data.resultMsg);
                reject(result.data);
            }
        })
            .then((err) => {
            reject(err);
        });
    });
}
function platRequest(url, method, data) {
    const token = GetAuthToken();
    return new Promise((relove, reject) => {
        const config = {
            url: url,
            method: method,
            headers: {
                Authorization: token,
                authStore: ShopService.GetCurrentShop().id
            },
        };
        if (method == "get") {
            config.params = data;
        }
        if (method == "post") {
            config.data = data;
        }
        axios(config)
            .then(result => {
            if (result.data.errno == ReLoginCode) {
                AccountService.Logout();
                return;
            }
            if (result.data.errno == 0) {
                relove(result.data);
            }
            else {
                ElMessage.error(result.data.msg);
                reject(result.data);
            }
        })
            .then(err => {
            reject(err);
        });
    });
}
export function DownLoad(url, filename, loadingText = '数据准备中，请稍候') {
    const token = GetAuthToken();
    const loading = ElLoading.service({
        lock: true,
        text: loadingText,
    });
    axios
        .get(baseUrl + url, {
        responseType: 'blob',
        headers: {
            Authorization: token,
            authStore: ShopService.GetCurrentShop().id
        },
    })
        .then((res) => {
        loading.close();
        fileDownload(res.data, filename);
    })
        .catch(() => {
        loading.close();
    });
}
export function DownLoadPost(url, filename, data = {}, loadingText = '数据准备中，请稍候') {
    const token = GetAuthToken();
    const loading = ElLoading.service({
        lock: true,
        text: loadingText,
    });
    axios.defaults.headers.Authorization = token;
    axios.defaults.headers.authStore = ShopService.GetCurrentShop().id;
    axios({
        method: 'post',
        url: baseUrl + url,
        data,
        responseType: 'blob',
    })
        .then((res) => {
        loading.close();
        res.request.response.text().then((text) => {
            try {
                const data = JSON.parse(text);
                ElMessage.warning({
                    message: data.resultMsg,
                    type: 'warning',
                });
            }
            catch (error) {
                fileDownload(res.data, filename);
            }
        });
    })
        .catch(() => {
        loading.close();
    });
}
export function Get(url, data) {
    return request(baseUrl + url, 'get', data);
}
export function Del(url, data) {
    return request(baseUrl + url, 'delete', data);
}
export function Post(url, data, hideWarning = false, headersConfig) {
    return request(baseUrl + url, 'post', data, hideWarning, headersConfig);
}
export function PlatGet(url, data) {
    return platRequest(baseUrl + url, 'get', data);
}
export function PlatDel(url, data) {
    return platRequest(baseUrl + url, 'delete', data);
}
export function PlatPost(url, data) {
    return platRequest(baseUrl + url, 'post', data);
}
export const UploadGoodsImgUrl = baseUrl + '/merchant/upload';
export const UploadDeliverUrl = baseUrl + '/deliver/import';
// 批量导入物流订单号
export const ShopUploadDeliverUrl = baseUrl + '/backend/shop/deliver/importExpress';
export function GetLoginInfo() {
    return new Promise((relove, reject) => {
        axios({
            method: 'post',
            url: baseUrl + '/merchant/auth',
            withCredentials: true,
        })
            .then(async (result) => {
            relove(result.data);
        })
            .then((err) => {
            reject(err);
        });
    });
}
export function DownLoadReform(url, filename, data = {}, headersConfig) {
    return new Promise((relove, reject) => {
        const token = GetAuthToken();
        axios.defaults.headers.Authorization = token;
        axios.defaults.headers.authStore = ShopService.GetCurrentShop().id;
        axios({
            method: 'post',
            url: baseUrl + url,
            data,
            responseType: 'blob',
            ...headersConfig,
        }).then((res) => {
            res.request.response
                .text()
                .then((text) => {
                try {
                    const data = JSON.parse(text);
                    relove(data);
                    ElMessage.warning({
                        message: data.resultMsg,
                        type: 'warning',
                    });
                }
                catch (error) {
                    fileDownload(res.data, filename);
                    relove(res);
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    });
}
