import { GetAuthToken } from "@/api/Request";
import ShopService from "@/services/Shop/ShopService";
import { computed, defineComponent, ref, watch } from "vue";
import UploadAction from "./uploadAction";
export default defineComponent({
    name: "goUpload",
    emits: ["onDel", "onAdd", "onPreview", "onBeforeUpload"],
    props: {
        actionUrl: {
            type: String,
            required: false,
            default: "",
        },
        imageList: {
            type: String,
            default: "",
        },
        limit: {
            type: Number,
            default: 1,
        },
        showPreviewAll: {
            type: Boolean,
            default: false,
        },
        sortAble: {
            type: Boolean,
            default: false,
        },
        onBeforeUpload: {
            type: Function,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        canRemove: {
            type: Boolean,
            default: true,
        },
        canAdd: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const CustomHeader = computed(() => {
            const token = GetAuthToken();
            return {
                Authorization: token,
                authStore: ShopService.GetCurrentShop().id
            };
        });
        const selectLimitCount = computed(() => {
            return props.limit - showImageList.length;
        });
        const { uploaded, remove, preview, beforeUpload, showImageList, dialogVisible, dialogImageUrl, previewAllFlag, previewAll, onChanged, toLeft, toRight, uploadDom, onExceed, onProgress, } = UploadAction(props, context);
        function initShowImageList() {
            if (props.imageList && props.imageList.length > 0) {
                showImageList.splice(0, showImageList.length);
                let source = props.imageList.split(",");
                for (let i = 0; i < source.length; i++) {
                    if (source[i]) {
                        showImageList.push({ uid: i, url: source[i], status: 1 });
                    }
                }
            }
        }
        initShowImageList();
        watch(props, () => {
            initShowImageList();
        });
        const hideAddIcon = computed(() => {
            return !props.canAdd || showImageList.length >= props.limit;
        });
        const getImgList = () => {
            return showImageList;
        };
        const allowMultiple = ref(props.multiple);
        const showImgActionIndex = ref(-1);
        return {
            CustomHeader,
            uploaded,
            remove,
            preview,
            beforeUpload,
            showImageList,
            dialogVisible,
            dialogImageUrl,
            previewAllFlag,
            previewAll,
            toLeft,
            toRight,
            hideAddIcon,
            getImgList,
            showImgActionIndex,
            allowMultiple,
            onChanged,
            uploadDom,
            onExceed,
            onProgress,
            selectLimitCount,
        };
    },
});
