import { OptionType, RecordType, VerifyStatus, } from "@/common/enum";
import ShopService from "@/services/Shop/ShopService";
import { Get, Post, UploadGoodsImgUrl } from "./Request";
export class SpecialIdentification {
}
export class AfterSaleConfig {
}
export class EditGoodsAllArgs {
    constructor() {
        this.applyUserId = "";
        this.approveInfo = "";
        this.approveStatus = 0;
        this.approveUserId = 0;
        this.createdTime = "";
        this.id = 0;
        this.merchantId = 0;
        this.optionType = OptionType.None;
        this.productNo = "";
        this.recordType = RecordType.None;
        this.remarks = "";
        this.updatedTime = "";
        this.verifyStatus = VerifyStatus.None;
        this.version = 0;
        this.vertifyTime = "";
        this.productInfo = new ProductInfo();
        this.merchantStoreId = ShopService.GetCurrentShop().id;
        this.saleModelModify = true;
    }
}
//批量导入商品
export class productBatchImportBasicDTO {
}
export class ImportGoodsAllInfo {
}
export class productBatchImportParam {
}
export class ProductInfo {
}
export const DefaultTemplateId = {
    entity: 1,
    virtual: 2,
};
//商品查询参数
export class QueryParames {
}
// 商品标签对象
export class TagValue {
}
// 待入库商品返回数据
export class WaitGoodsResponse {
}
// 商品返回数据
export class GoodsResponse {
}
//批量导入商品返回数据
export class BatchImportGoodsResponse {
}
/**
 *
 * @param data 商品
 * @returns 添加新商品
 */
export function CreateVertifyRecord(data) {
    return Post("/vertifyRecord/create", data);
}
/**
 * 商品分类实体
 */
export class GoodsCategory {
    constructor() {
        this.id = 0;
        this.name = "";
        this.parentId = 0;
        this.children = new Array();
    }
}
export class CateParames {
}
/**
 * 获取所有的商品分类
 * @returns 商品分类
 */
export function GetAllGoodsCategorys() {
    return Get("/productCategory/list/withChildren");
}
/**
 * 上传图地址
 */
export const UploadUrl = UploadGoodsImgUrl;
/**
 *
 * @param data 分页查询标签
 * @returns
 */
export function QueryTags(data) {
    return Get("/label/list", data);
}
/**
 *
 * @param data
 * @returns 保存数据
 */
export function SaveVerifyRecord(data) {
    return Post("/product/verify/save", data);
}
/**
 * 提交审核
 */
export function CommitVerify(data) {
    return Post("/vertifyRecord/submit", data);
}
export function GoodsVerify(data) {
    return Post("/product/verify/submit", data);
}
/**
 * 商品分页查询
 */
export function GetGoodsList(data) {
    return Get(`/storeProductSelection/list?merchantStoreId=${data.merchantStoreId}&pageNum=${data.pageNum}&pageSize=${data.pageSize}&endTime=${data.endTime}&startTime=${data.startTime}&name=${data.name}&productCategoryId=${data.productCategoryId}&productNo=${data.productNo}&saleStatus=${data.saleStatus}&source=${data.source}&sortWay=${data.sortWay}&publishStatus=${data.publishStatus}&stockStatus=${data.stockStatus}`);
}
/**
 * 待入库商品分页查询 /vertifyRecord/waitingList
 */
export function GetWaitGoodsList(data) {
    return Post("/product/verify/waitingList", data);
}
/**
 * 获取所有商户列表
 */
export function GetMerchanList() {
    return Get("/merchant/list");
}
/**
 * 切换商户时候，需要更新给后台
 * @param data {id:}
 * @returns
 */
export function ChangeMerchanInfo(data) {
    return Get("/merchant/info?id=" + data.id);
}
/**
 *
 * @param id id
 * @returns 根据id获取到的待入库商品
 */
export function GetVerfifyGoodsInfoById(id) {
    return Get("/product/verify/get/" + id);
}
/**
 *
 * @param id id
 * @returns 根据id获取到的商品
 */
export function GetGoodsInfoById(id) {
    return Get("/product/get/" + id);
}
/**
 *
 * @param productNo
 * @returns 根据productNo获取到的商品
 */
export function GetGoodsByProductNo(productNo) {
    return Get("/product/verify/getByProductNo/" + productNo);
}
/**
 * 删除待入库商品
 * @param Id
 * @returns
 */
export function DelWaitGoods(Id) {
    return Get("/product/verify/delete/" + Id);
}
export class ModifyGoodsBaseInfoArgs {
}
/**
 * 调整价格库存日期-上下架
 */
export function ModifyGoodsBaseInfo(data) {
    return Post("/product/edit", data);
}
/**
 * 撤回商品
 * @param id /vertifyRecord/submit/
 */
export function ReturnVertifyGoods(id) {
    return Get("/product/verify/submit/" + id);
}
export function GetMerchantDetail() {
    return Get("/merchant/detail");
}
export function ImportDeliver(data) {
    return Post("/deliver/import", data);
}
// 根据商品ProductNo获取商品详情
export function GetByProductNo(productNo) {
    return Get("/product/getByProductNo/" + productNo);
}
// 获取店铺订单列表
export function GetOrderList(data) {
    return Post("/order/list", data);
}
// 获取知客登录信息
export function getZhikeToken() {
    return Get("/zhike/token");
}
// 获取商品SKU-自营商品
export function GetProductSku(data) {
    return Get("/product/list/skuInfo?productNo=" + data.productNo);
}
// 获取商品SKU-分销商品
export function GetProductPriceSku(data) {
    return Get("/product/list/salePrice/skuInfo?productNo=" + data.productNo);
}
// 商品管理-调整自营商品价格库存
export function EditSkuData(data) {
    return Post("/product/edit/skuInfo", data);
}
// 调整分销商品零售价
export function EditSellSkuData(data) {
    return Post("/product/edit/salePrice/skuInfo", data);
}
// 获取生产厂家信息
export function GetProductSupplierInfo(data) {
    return Post("/product/productSupplier/Info", data);
}
// 获取生产厂家列表
export function GetProductSupplierList(data) {
    return Post("/product/productSupplier/List", data);
}
// 新增生产厂家
export function AddProductSupplier(data) {
    return Post("/product/productSupplier/add", data);
}
// 编辑生产厂家信息
export function EditProductSupplier(data) {
    return Post("/product/productSupplier/edit", data);
}
// 产品分类配置参数列表
export function GetProductCateList(data) {
    return Get("/productCategory/param/config/list/" + data.id);
}
//批量导入基础信息
export function ImportGoods(data) {
    return Post("/productbatch/basicinfo", data);
}
//导入全部信息
export function ImportAllData(data) {
    return Post("/productbatch/import", data, false, { 'Content-Type': 'multipart/form-data' });
}
//查询全部信息
export function GetAllData(data) {
    return Post("/productbatch/list", data);
}
