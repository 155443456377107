import { GetMerchantDetail } from '@/api/GoodsApi';
import { CommonStatus, MerchantSource } from '@/common/enum';
import { reactive } from 'vue';
import { Merchant } from './Merchant';
/**
 * 店铺管理服务
 */
export default class MerchantService {
    static get IsBusiness() {
        return this.shopDetails.source == MerchantSource.Business;
    }
    static get IsOPenZhike() {
        return this.shopDetails.openZhike || false;
    }
    /**
     *
     * @returns 商家是否开启分销市场
     */
    static get IsOpenProductSettle() {
        return this.shopDetails.openProductSettle == CommonStatus.Open;
    }
    /**
     *
     * @returns 商家信息
     */
    static async ShopDetailsAsync() {
        if (!this.shopDetails.id || this.shopDetails.id <= 0) {
            const result = await GetMerchantDetail();
            this.shopDetails = result.resultData;
        }
        return this.shopDetails;
    }
    static GetMerchantDetail() {
        return this.shopDetails;
    }
    // 退出
    static clearShop() {
        return (this.shopDetails = reactive(new Merchant()));
    }
}
MerchantService.shopDetails = reactive(new Merchant());
