import CategoryService from '@/services/Goods/CategoryService';
import Layout from 'views/Layout.vue';
export default {
    path: '/shop',
    name: 'shopmanagement',
    component: Layout,
    beforeEnter: (to, from, next) => {
        CategoryService.InitAllCatagory().then(res => {
            next();
        });
    },
    children: [
        {
            path: 'info',
            name: 'info',
            component: () => import('views/ShopManagement/BaseInfo/Index.vue')
        },
        {
            path: 'section',
            name: 'section',
            component: () => import('views/ShopManagement/SectionConfig/Index.vue')
        },
        {
            path: 'advert',
            name: 'advert',
            component: () => import('views/ShopManagement/AdvertConfig/Index.vue')
        },
        {
            path: 'channel',
            name: 'channel',
            component: () => import('views/ShopManagement/ChannelConfig/Index.vue')
        },
    ]
};
