import { onMounted, reactive, ref } from "vue";
import { UpdateLoginTime } from "@/api/GoShopApi";
import { ElMessageBox } from "element-plus";
import { StopBusiness, ReOpen } from "@/api/GoShopApi";
import ShopService from "@/services/Shop/ShopService";
import { shopFlag } from "@/common/enum";
export default function ShopList() {
    const queryArgs = reactive({
        shopName: "",
        shopFlag: shopFlag.ShopName
    });
    const shopLoading = ref(false);
    const handleToShop = async (info) => {
        info.loading = true;
        UpdateLoginTime(info.id).then(async () => {
            info.loading = false;
            await ShopService.InitCurrentShop(info.id);
            location.reload();
        }).catch(() => {
            info.loading = false;
        });
    };
    const handleClosed = (info) => {
        shopLoading.value = true;
        StopBusiness({ id: info.id }).then(async (res) => {
            const tempShops = await ShopService.InitAllShops({ pageNum: 1, pageSize: 100 });
            refreshShop(tempShops);
            shopLoading.value = false;
        });
    };
    const handleReopen = (info) => {
        ElMessageBox.alert('是否重新开始营业？', {
            confirmButtonText: '确定',
            callback: (res) => {
                if (res == 'cancel')
                    return;
                shopLoading.value = true;
                ReOpen({ id: info.id }).then(async (res) => {
                    const tempShops = await ShopService.InitAllShops({ pageNum: 1, pageSize: 100 });
                    refreshShop(tempShops);
                    shopLoading.value = false;
                }).catch(() => {
                    shopLoading.value = false;
                });
            },
        });
    };
    const shops = reactive(new Array());
    onMounted(() => {
        const tempShops = ShopService.GetAllShop();
        refreshShop(tempShops);
    });
    const refreshShop = (data) => {
        const list = data;
        shops.splice(0, shops.length);
        list.forEach(item => {
            shops.push(item);
        });
    };
    return {
        queryArgs,
        shops,
        handleToShop,
        handleClosed,
        shopLoading,
        handleReopen
    };
}
