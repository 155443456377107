// 审核状态
export var VerifyStatus;
(function (VerifyStatus) {
    VerifyStatus["None"] = "";
    /**
     * 待提交
     */
    VerifyStatus[VerifyStatus["WaitCommit"] = 1] = "WaitCommit";
    /**
     * 审核中
     */
    VerifyStatus[VerifyStatus["Verifing"] = 2] = "Verifing";
    /**
     * 审核成功
     */
    VerifyStatus[VerifyStatus["VerifSuccess"] = 3] = "VerifSuccess";
    /**
     * 审核拒绝
     */
    VerifyStatus[VerifyStatus["VerifReject"] = 4] = "VerifReject";
})(VerifyStatus || (VerifyStatus = {}));
//1.创建商品，2.编辑商品
export var OptionType;
(function (OptionType) {
    OptionType["None"] = "";
    OptionType[OptionType["Create"] = 1] = "Create";
    OptionType[OptionType["Edit"] = 2] = "Edit";
    OptionType[OptionType["Other"] = 3] = "Other";
})(OptionType || (OptionType = {}));
// 上架状态
export var PublishStatus;
(function (PublishStatus) {
    PublishStatus["None"] = "";
    // 待上架
    PublishStatus[PublishStatus["WaitPublish"] = 1] = "WaitPublish";
    // 已上架
    PublishStatus[PublishStatus["Published"] = 2] = "Published";
    // 下架
    PublishStatus[PublishStatus["UnPublished"] = 3] = "UnPublished";
})(PublishStatus || (PublishStatus = {}));
// 库存状态
export var StockStatus;
(function (StockStatus) {
    StockStatus["None"] = "";
    // 有库存
    StockStatus[StockStatus["HasStock"] = 1] = "HasStock";
    // 没库存
    StockStatus[StockStatus["NoStock"] = 2] = "NoStock";
})(StockStatus || (StockStatus = {}));
// 销售状态
export var SaleStatus;
(function (SaleStatus) {
    SaleStatus["None"] = "";
    // 销售中
    SaleStatus[SaleStatus["Saleing"] = 1] = "Saleing";
    // 未开始
    SaleStatus[SaleStatus["NoBegin"] = 2] = "NoBegin";
    // 已过期
    SaleStatus[SaleStatus["Expired"] = 3] = "Expired";
})(SaleStatus || (SaleStatus = {}));
// 商品类别
export var RecordType;
(function (RecordType) {
    RecordType[RecordType["None"] = 0] = "None";
    // 待入库商品
    RecordType[RecordType["WaitGoods"] = 1] = "WaitGoods";
    // 正常商品
    RecordType[RecordType["Goods"] = 2] = "Goods";
})(RecordType || (RecordType = {}));
// SELECTION_ADD_CHANGE(9, "新增选品"),
// SELECTION_MODIFY_CHANGE(10, "修改选品"),
// PRICING_CHANGE(11, "平台定价变动"),
// SELF_PRICE_CHANGE(12, "自营价格变动")
// 修改类型
export var ModifyType;
(function (ModifyType) {
    // 没变化
    ModifyType[ModifyType["NoChange"] = 1] = "NoChange";
    // 成本价
    ModifyType[ModifyType["Price"] = 2] = "Price";
    // 时间
    ModifyType[ModifyType["Time"] = 3] = "Time";
    // 库存
    ModifyType[ModifyType["Stock"] = 4] = "Stock";
    // 上架
    ModifyType[ModifyType["Publish"] = 5] = "Publish";
    // 下架
    ModifyType[ModifyType["UnPublish"] = 6] = "UnPublish";
    ModifyType[ModifyType["Other"] = 7] = "Other";
    // 市场价
    ModifyType[ModifyType["OriginalPrice"] = 8] = "OriginalPrice";
    // 自营价
    ModifyType[ModifyType["SelfSalePrice"] = 12] = "SelfSalePrice";
})(ModifyType || (ModifyType = {}));
// 发货管理-发货状态
export var LogisticStatus;
(function (LogisticStatus) {
    LogisticStatus["All"] = "";
    // 待发货
    LogisticStatus[LogisticStatus["Wait"] = 1] = "Wait";
    //已发货
    LogisticStatus[LogisticStatus["Sended"] = 2] = "Sended";
    // 退货处理
    LogisticStatus[LogisticStatus["Returning"] = 3] = "Returning";
    // 换货处理
    LogisticStatus[LogisticStatus["Replaceing"] = 4] = "Replaceing";
    // 已换货
    LogisticStatus[LogisticStatus["Replaced"] = 5] = "Replaced";
    // 已退货
    LogisticStatus[LogisticStatus["Returned"] = 6] = "Returned";
    // 拒绝退货
    LogisticStatus[LogisticStatus["RejectReturn"] = 7] = "RejectReturn";
    // 拒绝换货
    LogisticStatus[LogisticStatus["RejectReplace"] = 8] = "RejectReplace";
    // 已发货全部(除了1以外的)
    LogisticStatus[LogisticStatus["AllSended"] = 51] = "AllSended";
    // 处理中（3退货处理 4换货处理）
    LogisticStatus[LogisticStatus["ReturnAndReplace"] = 52] = "ReturnAndReplace";
    // 已拒绝（7拒绝退货 8拒绝换货 
    LogisticStatus[LogisticStatus["RejectReplaceAndReturn"] = 53] = "RejectReplaceAndReturn";
    // 退换货全部（不为1待发货 2 已发货）
    LogisticStatus[LogisticStatus["ReturnAndReplaceAll"] = 54] = "ReturnAndReplaceAll";
    // 无需发货
    LogisticStatus[LogisticStatus["NoRequired"] = 9] = "NoRequired";
    // 无法发货
    LogisticStatus[LogisticStatus["UnableShip"] = 10] = "UnableShip";
})(LogisticStatus || (LogisticStatus = {}));
// 退换货类型
export var RefundType;
(function (RefundType) {
    // 未退换
    RefundType[RefundType["NoReturn"] = 1] = "NoReturn";
    // 换货
    RefundType[RefundType["Replace"] = 2] = "Replace";
    // 退货
    RefundType[RefundType["Return"] = 3] = "Return";
})(RefundType || (RefundType = {}));
export var OrderType;
(function (OrderType) {
    OrderType[OrderType["PurchaseTime"] = 1] = "PurchaseTime";
    OrderType[OrderType["SendTime"] = 2] = "SendTime";
    OrderType[OrderType["AfterSaleApplyTime"] = 1] = "AfterSaleApplyTime";
    OrderType[OrderType["AfterSalePurchaseTime"] = 2] = "AfterSalePurchaseTime";
})(OrderType || (OrderType = {}));
export var SettleMethod;
(function (SettleMethod) {
    SettleMethod[SettleMethod["WxSettle"] = 1] = "WxSettle";
    SettleMethod[SettleMethod["Other"] = 2] = "Other";
})(SettleMethod || (SettleMethod = {}));
// 通用的一些开关
export var CommonStatus;
(function (CommonStatus) {
    CommonStatus[CommonStatus["Open"] = 1] = "Open";
    CommonStatus[CommonStatus["Close"] = 2] = "Close";
})(CommonStatus || (CommonStatus = {}));
export var shopFlag;
(function (shopFlag) {
    shopFlag[shopFlag["ShopName"] = 1] = "ShopName";
    shopFlag[shopFlag["ShopFlag"] = 2] = "ShopFlag";
})(shopFlag || (shopFlag = {}));
export var SortModel;
(function (SortModel) {
    SortModel[SortModel["CreateTime"] = 1] = "CreateTime";
    SortModel[SortModel["Price"] = 2] = "Price";
    SortModel[SortModel["Commission"] = 3] = "Commission";
})(SortModel || (SortModel = {}));
export var SortAllType;
(function (SortAllType) {
    SortAllType[SortAllType["IDDesc"] = 1] = "IDDesc";
    SortAllType[SortAllType["IDAsc"] = 2] = "IDAsc";
    SortAllType[SortAllType["SalePriceDesc"] = 3] = "SalePriceDesc";
    SortAllType[SortAllType["SalePriceAsc"] = 4] = "SalePriceAsc";
    SortAllType[SortAllType["CommissionDesc"] = 5] = "CommissionDesc";
    SortAllType[SortAllType["CommissionAsc"] = 6] = "CommissionAsc";
    SortAllType[SortAllType["CreateTimeAsc"] = 2] = "CreateTimeAsc";
    SortAllType[SortAllType["CreateTimeDesc"] = 1] = "CreateTimeDesc";
})(SortAllType || (SortAllType = {}));
export var ProductSourceType;
(function (ProductSourceType) {
    ProductSourceType["All"] = "";
    // 未设置
    ProductSourceType[ProductSourceType["unSet"] = 0] = "unSet";
    // 自营
    ProductSourceType[ProductSourceType["Self"] = 1] = "Self";
    // 分销
    ProductSourceType[ProductSourceType["Share"] = 2] = "Share";
})(ProductSourceType || (ProductSourceType = {}));
// 店铺频道上架状态
export var ShelfStatus;
(function (ShelfStatus) {
    // 已上架
    ShelfStatus[ShelfStatus["Shelfed"] = 1] = "Shelfed";
    // 未上架
    ShelfStatus[ShelfStatus["UnShelfed"] = 2] = "UnShelfed";
})(ShelfStatus || (ShelfStatus = {}));
// 店铺商品销售状态
export var ShopProSaleStatus;
(function (ShopProSaleStatus) {
    ShopProSaleStatus["All"] = "";
    ShopProSaleStatus[ShopProSaleStatus["Saleing"] = 1] = "Saleing";
    ShopProSaleStatus[ShopProSaleStatus["PauseSale"] = 2] = "PauseSale";
    ShopProSaleStatus[ShopProSaleStatus["Stop"] = 3] = "Stop";
})(ShopProSaleStatus || (ShopProSaleStatus = {}));
// 频道类别
export var SectionType;
(function (SectionType) {
    SectionType[SectionType["Standard"] = 1] = "Standard";
    SectionType[SectionType["Custom"] = 2] = "Custom"; // 自定义频道
})(SectionType || (SectionType = {}));
// 商点状态
export var ShopStatus;
(function (ShopStatus) {
    ShopStatus[ShopStatus["NoSaleVerifying"] = 0] = "NoSaleVerifying";
    ShopStatus[ShopStatus["NoSaleVerifyReject"] = 1] = "NoSaleVerifyReject";
    ShopStatus[ShopStatus["Canceled"] = 2] = "Canceled";
    ShopStatus[ShopStatus["Saleing"] = 3] = "Saleing";
    ShopStatus[ShopStatus["SaleVerifying"] = 4] = "SaleVerifying";
    ShopStatus[ShopStatus["SaleVerifyReject"] = 5] = "SaleVerifyReject";
    ShopStatus[ShopStatus["Close"] = 6] = "Close"; // 已关闭
})(ShopStatus || (ShopStatus = {}));
export var LicenseType;
(function (LicenseType) {
    LicenseType[LicenseType["BusinessLicense"] = 1] = "BusinessLicense";
    LicenseType[LicenseType["OtherLicense"] = 2] = "OtherLicense"; // 其他执照
})(LicenseType || (LicenseType = {}));
export var MerchantSource;
(function (MerchantSource) {
    MerchantSource[MerchantSource["Normal"] = 1] = "Normal";
    MerchantSource[MerchantSource["Business"] = 2] = "Business"; // 商业化
})(MerchantSource || (MerchantSource = {}));
// 排序状态
export var sortDirection;
(function (sortDirection) {
    sortDirection[sortDirection["descending"] = 0] = "descending";
    sortDirection[sortDirection["ascending"] = 1] = "ascending";
})(sortDirection || (sortDirection = {}));
// 订单状态
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["All"] = "";
    OrderStatus[OrderStatus["Wait"] = 1] = "Wait";
    OrderStatus[OrderStatus["ToBeShipped"] = 2] = "ToBeShipped";
    OrderStatus[OrderStatus["ToBeReceived"] = 3] = "ToBeReceived";
    OrderStatus[OrderStatus["Finished"] = 4] = "Finished";
    OrderStatus[OrderStatus["ClosesOverTime"] = 21] = "ClosesOverTime";
    OrderStatus[OrderStatus["ClosesAfterSale"] = 24] = "ClosesAfterSale";
    OrderStatus[OrderStatus["ClosedBusiness"] = 22] = "ClosedBusiness";
    OrderStatus[OrderStatus["ClosedPlatform"] = 23] = "ClosedPlatform";
    OrderStatus[OrderStatus["ClosedCannotPay"] = 25] = "ClosedCannotPay";
    OrderStatus[OrderStatus["ClosedUserDeal"] = 26] = "ClosedUserDeal";
    // PaySuccess = 1,// 支付成功 - 付款成功
    // Refunding = 4,// 退款中 
    // Refunded = 3,// 退款成功
    // RefunedFail = 5,// 退款失败
    // Payfail = 2, // 付款失败
    // Exception = 6,// 订单异常-订单交易取消
    // Aftersale = 20, // 售后中
    // Expire = 5,// 已过期
})(OrderStatus || (OrderStatus = {}));
export var OrderDeliveryStatus;
(function (OrderDeliveryStatus) {
    OrderDeliveryStatus["All"] = "";
    // 无需发货
    OrderDeliveryStatus[OrderDeliveryStatus["NoRequired"] = 0] = "NoRequired";
    // 待发货
    OrderDeliveryStatus[OrderDeliveryStatus["Wait"] = 1] = "Wait";
    //已发货
    OrderDeliveryStatus[OrderDeliveryStatus["Sended"] = 2] = "Sended";
    // 无法发货
    OrderDeliveryStatus[OrderDeliveryStatus["Unable"] = 3] = "Unable";
})(OrderDeliveryStatus || (OrderDeliveryStatus = {}));
// 售后类型 - 退换货类型
export var AfterServiceType;
(function (AfterServiceType) {
    AfterServiceType["All"] = "";
    AfterServiceType[AfterServiceType["Refund"] = 1] = "Refund";
    AfterServiceType[AfterServiceType["ReturnGoods"] = 2] = "ReturnGoods";
    AfterServiceType[AfterServiceType["ExchangeGoods"] = 3] = "ExchangeGoods";
})(AfterServiceType || (AfterServiceType = {}));
// 售后处理状态
export var AfterServiceStatus;
(function (AfterServiceStatus) {
    AfterServiceStatus[AfterServiceStatus["All"] = 10] = "All";
    AfterServiceStatus[AfterServiceStatus["Pending"] = 1] = "Pending";
    AfterServiceStatus[AfterServiceStatus["Ing"] = 2] = "Ing";
    AfterServiceStatus[AfterServiceStatus["Pass"] = 3] = "Pass";
    AfterServiceStatus[AfterServiceStatus["Reject"] = 4] = "Reject";
    AfterServiceStatus[AfterServiceStatus["Closed"] = 5] = "Closed";
    AfterServiceStatus[AfterServiceStatus["ReturnExchange"] = 10] = "ReturnExchange";
})(AfterServiceStatus || (AfterServiceStatus = {}));
// 售后处理结果
export var AfterSaleResult;
(function (AfterSaleResult) {
    AfterSaleResult[AfterSaleResult["Agree"] = 1] = "Agree";
    AfterSaleResult[AfterSaleResult["Reject"] = 2] = "Reject";
})(AfterSaleResult || (AfterSaleResult = {}));
// 拒绝类型
export var AfterScaleReceivedType;
(function (AfterScaleReceivedType) {
    AfterScaleReceivedType["Normal"] = "";
    AfterScaleReceivedType[AfterScaleReceivedType["Exchange"] = 1] = "Exchange";
    AfterScaleReceivedType[AfterScaleReceivedType["Received"] = 2] = "Received";
})(AfterScaleReceivedType || (AfterScaleReceivedType = {}));
// 发货状态
// 1待发货 2已发货 3退货处理 4换货处理 5已换货 6已退货 7拒绝退货 8拒绝换货 9无需发货
export var ShipmentStatus;
(function (ShipmentStatus) {
    ShipmentStatus[ShipmentStatus["Pendding"] = 1] = "Pendding";
    ShipmentStatus[ShipmentStatus["Shippenf"] = 2] = "Shippenf";
    ShipmentStatus[ShipmentStatus["Return"] = 3] = "Return";
    ShipmentStatus[ShipmentStatus["Exchange"] = 4] = "Exchange";
    ShipmentStatus[ShipmentStatus["Exchanged"] = 5] = "Exchanged";
    ShipmentStatus[ShipmentStatus["Returned"] = 6] = "Returned";
    ShipmentStatus[ShipmentStatus["RejectReturn"] = 7] = "RejectReturn";
    ShipmentStatus[ShipmentStatus["RejectExchange"] = 8] = "RejectExchange";
    ShipmentStatus[ShipmentStatus["NoShippingRequired"] = 9] = "NoShippingRequired";
})(ShipmentStatus || (ShipmentStatus = {}));
// 售后 - 当前流程状态
export var CurrentProcessStatus;
(function (CurrentProcessStatus) {
    CurrentProcessStatus[CurrentProcessStatus["UserApplyExchange"] = 1] = "UserApplyExchange";
    CurrentProcessStatus[CurrentProcessStatus["BusinessDealExchange"] = 2] = "BusinessDealExchange";
    CurrentProcessStatus[CurrentProcessStatus["UserReturnGoods"] = 3] = "UserReturnGoods";
    CurrentProcessStatus[CurrentProcessStatus["BusinessReceived"] = 4] = "BusinessReceived";
    CurrentProcessStatus[CurrentProcessStatus["BusinessUploadLogisticeInfo"] = 5] = "BusinessUploadLogisticeInfo";
    CurrentProcessStatus[CurrentProcessStatus["AfterSalseProcessOver"] = 6] = "AfterSalseProcessOver";
})(CurrentProcessStatus || (CurrentProcessStatus = {}));
// 分销成本价、自营销售价状态
export var PriceStatus;
(function (PriceStatus) {
    PriceStatus[PriceStatus["none"] = 1] = "none";
    PriceStatus[PriceStatus["open"] = 1] = "open";
    PriceStatus[PriceStatus["close"] = 2] = "close";
})(PriceStatus || (PriceStatus = {}));
export var ProductType;
(function (ProductType) {
    ProductType[ProductType["All"] = 0] = "All";
    ProductType[ProductType["entity"] = 1] = "entity";
    ProductType[ProductType["virtual"] = 2] = "virtual"; //虚拟商品
})(ProductType || (ProductType = {}));
// 启用状态 1.正常启用 2.未被启用
export var SkuEnableStatus;
(function (SkuEnableStatus) {
    SkuEnableStatus[SkuEnableStatus["Normal"] = 1] = "Normal";
    SkuEnableStatus[SkuEnableStatus["Close"] = 2] = "Close";
})(SkuEnableStatus || (SkuEnableStatus = {}));
// 修改价格类型
export var EditPriceType;
(function (EditPriceType) {
    // 价格
    EditPriceType[EditPriceType["price"] = 1] = "price";
    //库存
    // 分销
    // price = 2,
    // 库存
    EditPriceType[EditPriceType["stock"] = 2] = "stock";
    // stock = 4,
    // 市场
    EditPriceType[EditPriceType["originalPrice"] = 8] = "originalPrice";
    // 自营价
    EditPriceType[EditPriceType["selfSalePrice"] = 12] = "selfSalePrice";
})(EditPriceType || (EditPriceType = {}));
// 优惠券核销状态
export var CouponWriteOffStatus;
(function (CouponWriteOffStatus) {
    // 全部
    CouponWriteOffStatus["All"] = "";
    // 未分配
    CouponWriteOffStatus[CouponWriteOffStatus["Unassigned"] = 1] = "Unassigned";
    // 已分配
    CouponWriteOffStatus[CouponWriteOffStatus["Assigned"] = 2] = "Assigned";
    // 已使用
    CouponWriteOffStatus[CouponWriteOffStatus["Used"] = 3] = "Used";
})(CouponWriteOffStatus || (CouponWriteOffStatus = {}));
// 优惠券可用状态
export var CouponAvailableStatus;
(function (CouponAvailableStatus) {
    // 全部
    CouponAvailableStatus["All"] = "";
    // 有效
    CouponAvailableStatus[CouponAvailableStatus["Effective"] = 1] = "Effective";
    // 失效
    CouponAvailableStatus[CouponAvailableStatus["Invalid"] = 2] = "Invalid";
    // 已过期
    CouponAvailableStatus[CouponAvailableStatus["Expired"] = 3] = "Expired";
})(CouponAvailableStatus || (CouponAvailableStatus = {}));
// 批次状态
export var BranchStatus;
(function (BranchStatus) {
    // 全部
    BranchStatus["All"] = "";
    // 正常
    BranchStatus[BranchStatus["Creating"] = 1] = "Creating";
    // 生成中
    BranchStatus[BranchStatus["Normal"] = 2] = "Normal";
    // 已停用
    BranchStatus[BranchStatus["Stoped"] = 3] = "Stoped";
    // 已抢光
    BranchStatus[BranchStatus["LootedAll"] = 4] = "LootedAll";
})(BranchStatus || (BranchStatus = {}));
// 优惠券类型
export var CouponType;
(function (CouponType) {
    // 定额
    CouponType[CouponType["Quota"] = 1] = "Quota";
    // 满额
    CouponType[CouponType["Full"] = 2] = "Full";
    // 折扣
    CouponType[CouponType["Rebate"] = 3] = "Rebate";
})(CouponType || (CouponType = {}));
// 优惠券获取方式-链接券，券码券
export var CouponObtainWay;
(function (CouponObtainWay) {
    // 手动
    CouponObtainWay[CouponObtainWay["Link"] = 1] = "Link";
    // 券码
    CouponObtainWay[CouponObtainWay["Couponcode"] = 2] = "Couponcode";
})(CouponObtainWay || (CouponObtainWay = {}));
// 创建优惠券-商品限制
export var CouponGoodsLimit;
(function (CouponGoodsLimit) {
    // 1、不限制 2、限制商品 3、限制分类 4、限制频道
    CouponGoodsLimit[CouponGoodsLimit["Null"] = 1] = "Null";
    CouponGoodsLimit[CouponGoodsLimit["Goods"] = 2] = "Goods";
    CouponGoodsLimit[CouponGoodsLimit["Category"] = 3] = "Category";
    CouponGoodsLimit[CouponGoodsLimit["Channel"] = 4] = "Channel";
})(CouponGoodsLimit || (CouponGoodsLimit = {}));
// 创建优惠券-店铺限制
export var CouponShopLimit;
(function (CouponShopLimit) {
    // 1、不限制2、限制店铺
    CouponShopLimit[CouponShopLimit["Null"] = 1] = "Null";
    CouponShopLimit[CouponShopLimit["Shop"] = 2] = "Shop";
})(CouponShopLimit || (CouponShopLimit = {}));
// 创建优惠券-有用有效区类型
export var CouponUseTimeType;
(function (CouponUseTimeType) {
    // 1、固定期限 2、领取后几天内
    CouponUseTimeType[CouponUseTimeType["Regular"] = 1] = "Regular";
    CouponUseTimeType[CouponUseTimeType["Custom"] = 2] = "Custom";
})(CouponUseTimeType || (CouponUseTimeType = {}));
export var EnableCoupon;
(function (EnableCoupon) {
    // 1：开启 2：关闭
    EnableCoupon[EnableCoupon["Open"] = 1] = "Open";
    EnableCoupon[EnableCoupon["Close"] = 2] = "Close";
})(EnableCoupon || (EnableCoupon = {}));
// 红包状态
export var LuckyMoneyStatus;
(function (LuckyMoneyStatus) {
    LuckyMoneyStatus["All"] = "";
    LuckyMoneyStatus[LuckyMoneyStatus["Ing"] = 1] = "Ing";
    LuckyMoneyStatus[LuckyMoneyStatus["Stop"] = 2] = "Stop";
    LuckyMoneyStatus[LuckyMoneyStatus["End"] = 3] = "End";
    LuckyMoneyStatus[LuckyMoneyStatus["UnStart"] = 4] = "UnStart";
})(LuckyMoneyStatus || (LuckyMoneyStatus = {}));
// 红包消费状态
export var ConsumotionStatus;
(function (ConsumotionStatus) {
    ConsumotionStatus[ConsumotionStatus["All"] = 0] = "All";
    ConsumotionStatus[ConsumotionStatus["NotConsumed"] = 1] = "NotConsumed";
    ConsumotionStatus[ConsumotionStatus["Consumed"] = 2] = "Consumed";
    ConsumotionStatus[ConsumotionStatus["Invalid"] = 3] = "Invalid";
})(ConsumotionStatus || (ConsumotionStatus = {}));
// 红包导出类型
export var EnvelopType;
(function (EnvelopType) {
    EnvelopType[EnvelopType["RedCollection"] = 1] = "RedCollection";
    EnvelopType[EnvelopType["PersonalAccount"] = 2] = "PersonalAccount";
    EnvelopType[EnvelopType["PersonalRecord"] = 3] = "PersonalRecord";
})(EnvelopType || (EnvelopType = {}));
// 模板类型
export var ModuleType;
(function (ModuleType) {
    ModuleType[ModuleType["Input"] = 1] = "Input";
    ModuleType[ModuleType["Address"] = 2] = "Address";
    ModuleType[ModuleType["Image"] = 3] = "Image";
    ModuleType[ModuleType["Text"] = 4] = "Text";
})(ModuleType || (ModuleType = {}));
// 是否支持售后 1-支持，2-不支持
export var AfterSalesStatus;
(function (AfterSalesStatus) {
    AfterSalesStatus[AfterSalesStatus["Open"] = 1] = "Open";
    AfterSalesStatus[AfterSalesStatus["Close"] = 2] = "Close";
})(AfterSalesStatus || (AfterSalesStatus = {}));
// 退款类型
(function (RefundType) {
    RefundType[RefundType["Radio"] = 1] = "Radio";
    RefundType[RefundType["Amount"] = 2] = "Amount";
})(RefundType || (RefundType = {}));
// 售后类型文案
export var AfterSaleType;
(function (AfterSaleType) {
    AfterSaleType["noPostage"] = "\u4E0D\u5305\u90AE";
    AfterSaleType["unsupportNoReaon"] = "\u4E0D\u652F\u63017\u5929\u65E0\u7406\u7531";
    AfterSaleType["partialRefund"] = "\u53D1\u8D27\u540E\u4E0D\u5168\u989D\u9000\u6B3E";
    AfterSaleType["specialStatement"] = "\u7279\u522B\u58F0\u660E";
})(AfterSaleType || (AfterSaleType = {}));
// 分类参数类型
export var CateParamesType;
(function (CateParamesType) {
    // 文本
    CateParamesType[CateParamesType["Text"] = 1] = "Text";
    // 金额
    CateParamesType[CateParamesType["Amount"] = 2] = "Amount";
    // 时间
    CateParamesType[CateParamesType["Time"] = 3] = "Time";
})(CateParamesType || (CateParamesType = {}));
// 店铺售后单列表-售后状态
export var ShopAfterSaleStatus;
(function (ShopAfterSaleStatus) {
    ShopAfterSaleStatus["All"] = "";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RefundPending"] = 200101] = "RefundPending";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RefundBusinessClosed"] = 200102] = "RefundBusinessClosed";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RefundAgree"] = 200103] = "RefundAgree";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RefundOver"] = 200104] = "RefundOver";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RefundUserClosed"] = 200105] = "RefundUserClosed";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundPending"] = 200201] = "RetrunRefundPending";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundBusinessClosed"] = 200202] = "RetrunRefundBusinessClosed";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundBusinessFirstAgree"] = 200203] = "RetrunRefundBusinessFirstAgree";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundUserSendBack"] = 200204] = "RetrunRefundUserSendBack";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundBusinessSecondAgree"] = 200206] = "RetrunRefundBusinessSecondAgree";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundOver"] = 200207] = "RetrunRefundOver";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundAutoClosed"] = 200208] = "RetrunRefundAutoClosed";
    ShopAfterSaleStatus[ShopAfterSaleStatus["RetrunRefundUserClosed"] = 200209] = "RetrunRefundUserClosed";
})(ShopAfterSaleStatus || (ShopAfterSaleStatus = {}));
// 售后类型 - 退换货类型 - 购物车需求
export var ShopAfterServiceType;
(function (ShopAfterServiceType) {
    ShopAfterServiceType[ShopAfterServiceType["All"] = 0] = "All";
    ShopAfterServiceType[ShopAfterServiceType["Refund"] = 1] = "Refund";
    ShopAfterServiceType[ShopAfterServiceType["ReturnGoods"] = 2] = "ReturnGoods";
    ShopAfterServiceType[ShopAfterServiceType["ExchangeGoods"] = 3] = "ExchangeGoods";
})(ShopAfterServiceType || (ShopAfterServiceType = {}));
// 售后处理状态 - 购物车需求
export var ShopAfterServiceStatus;
(function (ShopAfterServiceStatus) {
    ShopAfterServiceStatus[ShopAfterServiceStatus["All"] = 0] = "All";
    ShopAfterServiceStatus[ShopAfterServiceStatus["Ing"] = 1] = "Ing";
    ShopAfterServiceStatus[ShopAfterServiceStatus["Pass"] = 2] = "Pass";
    ShopAfterServiceStatus[ShopAfterServiceStatus["Reject"] = 3] = "Reject";
    ShopAfterServiceStatus[ShopAfterServiceStatus["Closed"] = 4] = "Closed";
})(ShopAfterServiceStatus || (ShopAfterServiceStatus = {}));
//频道批量操作
export var BatchEditSectionStatus;
(function (BatchEditSectionStatus) {
    BatchEditSectionStatus[BatchEditSectionStatus["All"] = 0] = "All";
    BatchEditSectionStatus[BatchEditSectionStatus["Add"] = 1] = "Add";
    BatchEditSectionStatus[BatchEditSectionStatus["Delete"] = 2] = "Delete";
})(BatchEditSectionStatus || (BatchEditSectionStatus = {}));
//批量导入商品状态
export var ImportGoodsStatus;
(function (ImportGoodsStatus) {
    ImportGoodsStatus["None"] = "";
    ImportGoodsStatus[ImportGoodsStatus["Importing"] = 1] = "Importing";
    ImportGoodsStatus[ImportGoodsStatus["Success"] = 2] = "Success";
    ImportGoodsStatus[ImportGoodsStatus["SomeSuccess"] = 3] = "SomeSuccess";
    ImportGoodsStatus[ImportGoodsStatus["Failed"] = 4] = "Failed";
})(ImportGoodsStatus || (ImportGoodsStatus = {}));
