import { createRouter, createWebHashHistory } from 'vue-router';
import LoginRouter from '@/router/login';
import GoodsRouter from '@/router/goods';
import ShopRouter from '@/router/shop';
import OrdersRouter from '@/router/orders';
import ShopAfterSaleRouter from '@/router/shopAfterSale';
import AfterSaleRouter from '@/router/afterSale';
import DeliveryRouter from '@/router/delivery';
import Settings from '@/router/settings';
import Market from '@/router/marketing';
import AccountService from '@/services/Account/AccountService';
import SkuServices from '@/services/Skus/SkuServices';
import SpecService from '@/services/Specs/SpecServices';
import Layout from 'views/Layout.vue';
import ShopService from '@/services/Shop/ShopService';
import ChannelService from '@/services/Channel/ChannelService';
import { UpdateLoginTime } from '@/api/GoShopApi';
const routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: '/home',
        name: 'home',
        component: Layout,
        children: [
            {
                path: '',
                name: 'pageindex',
                component: () => import('views/Index/Index.vue')
            },
        ]
    },
    ShopRouter,
    LoginRouter,
    GoodsRouter,
    OrdersRouter,
    ShopAfterSaleRouter,
    AfterSaleRouter,
    Settings,
    Market,
    DeliveryRouter
];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    const loginInfo = AccountService.GetLoginInfo();
    if (to.name !== 'login' && !(loginInfo?.token)) {
        next({ name: 'login' });
        return;
    }
    if (to.path == "/login") {
        next();
    }
    else {
        SkuServices.ClearSkus();
        SkuServices.ResetStatus();
        SpecService.ClearSpecs();
        Promise.all([
            ShopService.InitAllShops({ pageNum: 1, pageSize: 100 }),
        ]).then(([num]) => {
            const shopList = ShopService.GetAllShop();
            if (ShopService.CurrentShopIsNull()) {
                if (shopList.length > 0) {
                    if (!loginInfo?.storeId) {
                        UpdateLoginTime(shopList[0].id).then(res => {
                            ShopService.InitCurrentShop(shopList[0].id);
                            ChannelService.InitChannelList();
                            next();
                        });
                    }
                    else {
                        shopList.forEach((i) => {
                            if (i.id == loginInfo?.storeId) {
                                ShopService.InitCurrentShop(i.id);
                                ChannelService.InitChannelList();
                            }
                            next();
                        });
                    }
                }
                else {
                    next();
                }
            }
            else {
                const currentShop = ShopService.GetCurrentShop();
                ShopService.InitCurrentShop(currentShop.id || 0);
                ChannelService.InitChannelList();
                next();
            }
        }).catch(() => {
            window.location.reload();
        });
    }
});
export default router;
