import { reactive, ref } from "vue";
import { UploadUrl } from "@/api/GoodsApi";
import { ElMessage } from "element-plus";
import { GetImageSize } from "@/common/utils";
import { Debounce, CheckShopName, CheckStoreNo } from './debounce';
import { ShopSubmit } from "@/api/GoShopApi";
import eventBus from "@/utils/eventBus";
import { cancelCreateShop, createShopSuccess } from "@/utils/eventBusConst";
import { OptionType } from "@/common/enum";
import { Shop } from "@/services/Shop/Shop";
import AccountService from "@/services/Account/AccountService";
import ShopService from "@/services/Shop/ShopService";
export default function CreateShop() {
    const shopInfo = reactive(new Shop());
    const uploadUrl = UploadUrl;
    const ruleForm = ref(null);
    const formLoading = ref(false);
    const rules = {
        name: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
    };
    const close = () => {
        ruleForm.value.resetFields();
        eventBus.emit(cancelCreateShop);
    };
    const submit = () => {
        ruleForm.value.validate((valid) => {
            if (valid) {
                formLoading.value = true;
                ShopSubmit({ ...shopInfo, optionType: OptionType.Create, merchantId: AccountService.GetLoginInfo()?.merchantId }).then(res => {
                    const shops = ShopService.GetAllShop();
                    if (shops.length > 0) {
                        ShopService.RefreshAllShops({ pageNum: 1, pageSize: 100 }).then(shops => {
                            formLoading.value = false;
                            eventBus.emit(createShopSuccess);
                            ElMessage.success(res.resultMsg);
                        });
                    }
                    else {
                        formLoading.value = false;
                        eventBus.emit(createShopSuccess);
                        ElMessage.success(res.resultMsg);
                    }
                }).catch(() => {
                    // ElMessage.warning(err.resultMsg);
                    formLoading.value = false;
                });
            }
            else {
                return false;
            }
        });
    };
    const uploadImg = (key, value) => {
        shopInfo[key] = value.resultData;
    };
    const beforeDetailUpload = (w, h, val) => {
        return new Promise((reslove, reject) => {
            GetImageSize(val).then((res) => {
                if (res.width != w && res.height != h) {
                    ElMessage.error(`图片上传失败，图片大尺寸为${w}*${h}`);
                    reject();
                }
                reslove();
            });
        });
    };
    const remove = (key, val) => {
        shopInfo[key] = '';
    };
    const handleInput = (type, event) => {
        Debounce(type == 1 ? CheckShopName : CheckStoreNo, event, 500, '');
    };
    return {
        formLoading,
        shopInfo,
        uploadUrl,
        rules,
        close,
        submit,
        ruleForm,
        uploadImg,
        beforeDetailUpload,
        remove,
        handleInput
    };
}
