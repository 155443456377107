import CategoryService from '@/services/Goods/CategoryService';
import Layout from 'views/Layout.vue';
export default {
    path: '/orders',
    name: 'shopOrder',
    component: Layout,
    beforeEnter: (to, from, next) => {
        CategoryService.InitAllCatagory().then(res => {
            next();
        });
    },
    children: [
        {
            path: '',
            name: 'shopOrderIndex',
            component: () => import('views/ShopManagement/Order/Index.vue')
        },
    ]
};
