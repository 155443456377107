import AccountService from "@/services/Account/AccountService";
import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
    name: "LoginPage",
    setup() {
        onMounted(() => {
            AccountService.Login();
        });
        let currentUser = ref(0);
        return {
            currentUser,
        };
    },
});
