import UserInfo from "./UserInfo";
import { reactive } from '@vue/reactivity';
import CookieService from "../Store/CooikeService";
import router from "../../router/index";
import { GetLoginInfo } from '@/api/Request';
import { ElMessage } from "element-plus";
import MerchantService from "@/services/Merchant/MerchantService";
import ShopService from "../Shop/ShopService";
import { UpdateLoginTime } from "@/api/GoShopApi";
/**
 * 账号信息服务类
 */
export default class AccountService {
    /**
     * 设置用户信息
     * @param userInfo 用户信息
     */
    static SetUserInfo(userInfo) {
        this.UserInfo = userInfo;
        CookieService.SetCookie(this.UserInfoCookiePriex, this.UserInfo);
    }
    /**
     * 获取用户信息
     * @returns 用户信息
     */
    static GetUserInfo() {
        return CookieService.GetCookie(this.UserInfoCookiePriex);
    }
    static GetLoginInfo() {
        return CookieService.GetCookie('loginInfo');
    }
    /**
     * 登入
     */
    static Login() {
        $.getScript("//s.ssl.qhimg.com/quc/quc6.js").then(function () {
            // 业务 src： 若新业务一定要到首页先申请src
            QHPass.init({
                src: AccountService.QHPassSrc,
                domainList: ['6360.cn']
            });
            QHPass.setConfig("supportHttps", ['6360.cn']);
            QHPass.signIn($("#js-sdk"), (data) => {
                GetLoginInfo().then(result => {
                    // 员工福利商家
                    if (result.resultData && result.resultData.merchantSource == 4) {
                        ElMessage.error('权限禁止');
                        AccountService.Login();
                        return;
                    }
                    if (result.isSuccess) {
                        CookieService.SetCookie('loginInfo', result.resultData);
                        if (result.resultData.storeId) {
                            AccountService.SetUserInfo(data);
                            UpdateLoginTime(result.resultData.storeId).then(() => {
                                router.push({ path: "/home" });
                            });
                        }
                        else {
                            ShopService.ResetCurrentShop();
                            router.push({ path: "/home" });
                        }
                    }
                    else {
                        ElMessage.error(result.resultMsg);
                        AccountService.Login();
                    }
                }).catch(err => {
                    ElMessage.error(err);
                    AccountService.Login();
                });
            });
        });
    }
    /**
     * 登出
     */
    static Logout() {
        MerchantService.clearShop();
        CookieService.RemoveCookie(this.UserInfoCookiePriex);
        CookieService.RemoveCookie('loginInfo');
        ShopService.ClearCurrenShop();
        $.getScript("//s.ssl.qhimg.com/quc/quc6.js").then(() => {
            QHPass.signOut(true, true);
        });
    }
}
AccountService.UserInfo = reactive(new UserInfo());
/**
 * 用户信息存储在cookie中的前缀
 */
AccountService.UserInfoCookiePriex = "userInfo";
/**
 * 业务线申请的登录src
 */
AccountService.QHPassSrc = "pcw_shop_admin";
