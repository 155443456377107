import { ConfirmGOShopName, ConfirmGOShopNo } from "@/api//GoShopApi";
import { ElMessage } from "element-plus";
let timeout;
export function Debounce(fn, event, wait, oldValue) {
    if (timeout)
        clearTimeout(timeout);
    timeout = setTimeout(() => {
        fn(event, oldValue);
    }, wait);
}
export function CheckShopName(event, oldValue) {
    if (!event)
        return;
    if (oldValue != event)
        ConfirmGOShopName(event).then((res) => {
            if (!res.isSuccess)
                ElMessage.warning(`店铺名称${res.resultMsg}`);
        });
}
export function CheckStoreNo(event) {
    if (!event)
        return;
    ConfirmGOShopNo(event).then((res) => {
        if (!res.isSuccess)
            ElMessage.warning(`店铺标识${res.resultMsg}`);
    });
}
