import { defineComponent } from "vue";
export default defineComponent({
    props: {
        menu: {
            type: Object,
            required: true,
        },
        activeId: {
            type: String,
        },
    },
    setup() {
        return {};
    },
});
