import Layout from 'views/Layout.vue';
export default {
    path: '/marketing',
    name: 'marketing',
    component: Layout,
    children: [
        {
            path: 'coupon',
            name: 'coupon',
            component: () => import('views/Marketing/Coupon/Index.vue')
        },
        {
            path: 'envelope',
            name: 'envelope',
            component: () => import('views/Marketing/Envelopes/Index.vue')
        },
    ]
};
