// 当sku初始化完成时事件
export const onInitSkused = "initSkused";
// 当规格内容变化事件
export const specChanged = "specChanged";
// 校验时候SKU出错事件
export const onSkuError = "onSkuError";
// 我的商品编辑SKU确认事件
export const onSkuEdited = "onSkuEdited";
// 编辑商品时，数据加载事件
export const onEditDataIniting = "onEditDataIniting";
// 编辑商品时，商品分类参数加载完成事件
export const onEditCateParamsLoaded = "onEditCateParamsLoaded";
// 打开右侧抽屉事件
export const onOpenDrawer = "onOpenDrawer";
// 关闭右侧抽屉事件
export const closeOpenDrawer = "closeOpenDrawer";
// 接受到新消息的事件
export const receiveNewMessage = "onReceiveNewMessage";
// 接收到新的重要消息事件
export const receiveNewImportMessage = "onReceiveNewImportMessage";
// 创建店铺事件
export const createShop = "createShop";
// 展示引导创建店铺弹窗
export const showCreateShop = "showCreateShop";
// 取消创建店铺事件
export const cancelCreateShop = "cancelCreateShop";
// 创建店铺成功
export const createShopSuccess = "createShopSuccess";
// 切换店铺事件
export const changeShop = "changeShop";
// 设置router-container自定义样式
export const setRouterCustomClass = "setRouterCustomClass";
// 待处理数量改变时的事件
export const onTipsCountChanged = "onTipsCountChanged";
// 商家信息事件
export const openMerchantInfoDialog = "openMerchantInfoDialog";
// 处理已读消息事件
export const DealImportMessage = "DealImportMessage";
// 更新未读消息数量
export const UpdateUnreadNum = "UpdateUnreadNum";
// 更新首页消息列表轮播消息
export const UpdateIndexMessageSwiper = "UpdateIndexMessageSwiper";
