import CategoryService from '@/services/Goods/CategoryService';
import MerchantService from '@/services/Merchant/MerchantService';
import Layout from 'views/Layout.vue';
export default {
    path: '/goods',
    name: 'goods',
    component: Layout,
    beforeEnter: (to, from, next) => {
        Promise.all([CategoryService.InitAllCatagory(), MerchantService.ShopDetailsAsync()]).then(res => {
            next();
        });
    },
    children: [
        {
            path: '',
            name: 'goodslist',
            component: () => import('views/GoodsManagement/Mine/Mine.vue')
        },
        {
            path: "empty",
            name: 'empty',
            component: () => import('views/UtilPages/Empty.vue')
        },
        {
            path: 'wait',
            name: 'waitlist',
            component: () => import('views/GoodsManagement/Wait/Wait.vue')
        },
        {
            path: 'add',
            name: 'addGoods',
            component: () => import('views/GoodsManagement/Detail/Add.vue')
        },
        {
            path: 'edit/:productNo',
            name: 'edit',
            component: () => import('views/GoodsManagement/Detail/Add.vue')
        },
        {
            path: 'waitEdit/:id',
            name: 'waitEdit',
            component: () => import('views/GoodsManagement/Detail/Add.vue')
        },
        {
            path: 'copy/:productNo',
            name: 'copy',
            component: () => import('views/GoodsManagement/Detail/Add.vue')
        },
        {
            path: "select",
            name: 'select',
            component: () => import('views/GoodsManagement/SelectGoods/Index.vue')
        },
        {
            path: "import",
            name: 'import',
            component: () => import('views/GoodsManagement/Import/Import.vue')
        },
    ]
};
