import { PriceStatus, SkuEnableStatus } from "@/common/enum";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import SpecService from "../Specs/SpecServices";
import { SkuItem } from "./SkuModel";
export default class SkuServices {
    static AddSkus(item) {
        // 添加规格时候，如果sku中存在临时的规格，那么清除掉再添加
        if (this.skus.length == 1 && this.skus[0].id == "SKUNoSpec") {
            this.skus.splice(0, this.skus.length);
        }
        if (!item.id) {
            item.id = this.GetRandomSkuId();
        }
        this.skus.push(item);
    }
    static RemoveSku(index) {
        this.skus.splice(index, 1);
    }
    static InitSkusByList(list) {
        this.skus.splice(0, this.skus.length);
        list.forEach(x => {
            this.skus.push(x);
        });
    }
    static UpdateSkusEnableStatus(val) {
        this.skus.forEach(x => {
            x.enableStatus = val ? SkuEnableStatus.Normal : SkuEnableStatus.Close;
        });
    }
    static UpdateAllEnableStatus() {
        const closeSkus = this.skus.find(s => s.enableStatus == SkuEnableStatus.Close);
        if (closeSkus && closeSkus.id) {
            this.allEnable.value = false;
            return;
        }
        this.allEnable.value = true;
    }
    static ClearSkus() {
        this.skus.splice(0, this.skus.length);
        // 永远保证sku列表中存在一个sku，定价组件会同步数据到这里
        const newSku = new SkuItem();
        newSku.id = "SKUNoSpec";
        this.skus.push(newSku);
    }
    static SetStatus(settleStatus, selfSaleStatus) {
        this.settleStatus.value = settleStatus || PriceStatus.close;
        this.selfSaleStatus.value = selfSaleStatus || PriceStatus.close;
    }
    static ResetStatus() {
        this.settleStatus.value = PriceStatus.none;
        this.selfSaleStatus.value = PriceStatus.none;
    }
    static InsertSku(index, item) {
        this.skus.splice(index, 0, item);
    }
    static GetRandomSkuId() {
        return "SKU" + (new Date().getTime()) + this.skus.length;
    }
    static UpdateSkusPriceAndSotck(val, validatePrice = '') {
        this.skus.forEach(x => {
            if (validatePrice === '') {
                x.originalPrice = val.originalPrice;
                x.price = val.price;
                x.selfSalePrice = val.selfSalePrice;
                x.stock = val.stock;
            }
            if (validatePrice == 'stock') {
                x.stock = val.stock;
            }
            if (validatePrice == 'selfSale') {
                x.originalPrice = val.originalPrice;
                x.price = val.price;
                x.selfSalePrice = val.selfSalePrice;
            }
            if (validatePrice == 'unSelfSale') {
                x.selfSalePrice = val.selfSalePrice;
            }
        });
    }
    static SkusValid() {
        if (this.skus.length > this.MaxSkuCount) {
            ElMessage.error("超出最大SKU数量");
            return false;
        }
        this.errors.splice(0, this.errors.length);
        let result = true;
        const showCloumns = SkuServices.showCloumns;
        for (let i = 0; i < this.skus.length; i++) {
            if (this.skus[i].enableStatus == SkuEnableStatus.Close) {
                continue;
            }
            for (let j = 0; j < showCloumns.length; j++) {
                if (this.skus[i][showCloumns[j].value] <= 0) {
                    this.errors.push({ id: this.skus[i].id, key: showCloumns[j].value });
                    result = false;
                }
            }
        }
        return result;
    }
    static SkusUnSelfSaleValid() {
        this.errors.splice(0, this.errors.length);
        let result = true;
        const showCloumns = SkuServices.showCloumns;
        for (let i = 0; i < this.skus.length; i++) {
            for (let j = 0; j < showCloumns.length; j++) {
                if (this.skus[i][showCloumns[j].value] <= 0) {
                    this.errors.push({ id: this.skus[i].id, key: showCloumns[j].value });
                    result = false;
                }
                if (this.skus[i].selfSalePrice < this.skus[i].platformPricingPrice) {
                    this.errors.push({ id: this.skus[i].id, key: "selfSalePrice" });
                    ElMessage.error("销售价不能小于平台成本价");
                    return false;
                }
            }
        }
        return result;
    }
    static GetSkus() {
        if (SpecService.GetSpecs().length > 0) {
            this.skus.forEach((i) => {
                if (i.specList) {
                    i.specContentIds = i.specList.map((specs) => {
                        return specs.id;
                    }).join('_');
                }
            });
        }
        return this.skus;
    }
    static GetSkusNoId() {
        this.skus.forEach((i) => {
            if (SpecService.GetSpecs().length > 0) {
                if (i.specList && i.specList.length > 0) {
                    i.specContentIds = i.specList.map((specs) => {
                        return specs.id;
                    }).join('_');
                }
            }
            if (i.id && (i.id + '').indexOf('SKU') == 0) {
                i.id = "";
            }
        });
        return this.skus;
    }
    static GetAllEnableSkus() {
        return this.skus.filter(item => item.enableStatus == SkuEnableStatus.Normal);
    }
    static ChangePriceStatus(prop, val) {
        this[prop] = val;
    }
    static GetColumns() {
        return this.showCloumns;
    }
    static AddCloumn(cloumn) {
        this.showCloumns.push(cloumn);
    }
    static RemoveCloumn(value) {
        this.showCloumns.forEach((i, index) => {
            if (i.value == value) {
                this.showCloumns.splice(index, 1);
            }
        });
    }
    static GetTableWidth() {
        let result = 0;
        for (let i = 0; i < SpecService.GetSpecs().length; i++) {
            result += 200;
        }
        for (let i = 0; i < this.showCloumns.length; i++) {
            result += (this.showCloumns[i].width || 150);
        }
        return result;
    }
}
SkuServices.skus = reactive(new Array());
SkuServices.settleStatus = ref(PriceStatus.open);
SkuServices.selfSaleStatus = ref(PriceStatus.open);
SkuServices.showCloumns = reactive(new Array());
SkuServices.MaxSkuCount = 600;
// 收集错误信息
SkuServices.errors = reactive(new Array());
SkuServices.allEnable = ref(false);
SkuServices.UpdateColumns = (columns) => {
    SkuServices.showCloumns.splice(0, SkuServices.showCloumns.length);
    columns.forEach((i) => {
        SkuServices.showCloumns.push(i);
    });
};
