import { LogisticStatus, OptionType, PublishStatus, VerifyStatus, SettleMethod, ProductSourceType, ShopProSaleStatus, OrderStatus, OrderDeliveryStatus, AfterServiceStatus, AfterServiceType, ShipmentStatus, EditPriceType, BranchStatus, CouponGoodsLimit, CouponWriteOffStatus, CouponAvailableStatus, CouponObtainWay, LuckyMoneyStatus, ConsumotionStatus, ProductType, ShopAfterSaleStatus, ImportGoodsStatus } from "./enum";
export function TranslateVerifyStatus(status) {
    switch (status) {
        case VerifyStatus.WaitCommit:
            return "待提交";
        case VerifyStatus.Verifing:
            return "审核中";
        case VerifyStatus.VerifSuccess:
            return "审核通过";
        case VerifyStatus.VerifReject:
            return "已驳回";
        default:
            return "";
    }
}
export function TranslatePublishStatus(status) {
    switch (status) {
        case PublishStatus.Published:
            return "已上架";
        case PublishStatus.UnPublished:
            return "已下架";
        case PublishStatus.WaitPublish:
            return "待上架";
        default:
            return "";
    }
}
// 发货管理 - 发货状态
export function TranslateLogisticStatus(status) {
    switch (status) {
        case LogisticStatus.Wait:
            return "待退换";
        case LogisticStatus.Sended:
            return "已发货";
        case LogisticStatus.Returning:
            return "退货处理中";
        case LogisticStatus.Replaceing:
            return "换货处理中";
        case LogisticStatus.Replaced:
            return "已换货";
        case LogisticStatus.Returned:
            return "已退货";
        case LogisticStatus.RejectReturn:
            return "拒绝退货";
        case LogisticStatus.RejectReplace:
            return "拒绝换货";
        case LogisticStatus.NoRequired:
            return "无需发货";
        case LogisticStatus.UnableShip:
            return "无法发货";
        default:
            return "";
    }
}
export function TranslateSettleMethod(settleMethod) {
    switch (settleMethod) {
        case SettleMethod.WxSettle:
            return "微信实时分账";
        case SettleMethod.Other:
            return "线下结算";
        default:
            return "";
    }
}
export function TranslateProSource(source) {
    switch (source) {
        case ProductSourceType.Self:
            return "自营市场";
        case ProductSourceType.Share:
            return "分销市场";
        default:
            return "";
    }
}
export function TranslateShopProductStatus(state) {
    switch (state) {
        case ShopProSaleStatus.PauseSale:
            return "暂停销售";
        case ShopProSaleStatus.Saleing:
            return "销售中";
        default:
            return "";
    }
}
/*
* date 需要格式化的时间
* farmatStr 需要格式化的样式 如：yyyy-MM-dd
*/
export function FormatterDateTime(dateStr, formatStr) {
    if (!dateStr) {
        return "";
    }
    const date = new Date(dateStr);
    const opt = {
        'yyyy': date.getFullYear(),
        'MM': addZero(date.getMonth() + 1),
        'M': date.getMonth() + 1,
        'dd': addZero(date.getDate()),
        'd': date.getDate(),
        'hh': addZero(date.getHours()),
        'h': date.getHours(),
        'mm': addZero(date.getMinutes()),
        'm': date.getMinutes(),
        'ss': addZero(date.getSeconds()),
        's': date.getSeconds()
    };
    // 如果是个位数则前面添加0
    function addZero(value) {
        return value < 10 ? '0' + value : value;
    }
    // 遍历替换
    for (const k in opt) {
        formatStr = formatStr.replace(k, opt[k]);
    }
    return formatStr;
}
export function TranslateOptionType(optionType) {
    switch (optionType) {
        case OptionType.Create:
            return "创建";
        case OptionType.Edit:
            return "编辑";
        default:
            return "";
    }
}
//  0:待支付 1:成功 2:失败 3:退款成功 4:退款中 5:退款失败 6:订单交易取消
export function TranslateOrderStatus(status) {
    switch (status) {
        case OrderStatus.All:
            return "全部";
        case OrderStatus.Wait:
            return "待支付";
        case OrderStatus.ToBeShipped:
            return "待发货";
        case OrderStatus.ToBeReceived:
            return "待收货";
        case OrderStatus.Finished:
            return "已完成";
        case OrderStatus.ClosesOverTime:
            return "已关闭-自动关闭";
        case OrderStatus.ClosesAfterSale:
            return "已关闭-售后关闭";
        case OrderStatus.ClosedBusiness:
            return "已关闭-商家关闭";
        case OrderStatus.ClosedPlatform:
            return "已关闭-平台关闭";
        case OrderStatus.ClosedCannotPay:
            return "已关闭-订单不可支付，自动退款";
        case OrderStatus.ClosedUserDeal:
            return "已关闭-用户关闭";
        default:
            return "";
    }
}
// 店铺订单 - 发货状态
export function TranslateOrderDeliveryStatus(status) {
    switch (status) {
        case OrderDeliveryStatus.All:
            return "全部";
        case OrderDeliveryStatus.NoRequired:
            return "无需发货";
        case OrderDeliveryStatus.Wait:
            return "未发货";
        case OrderDeliveryStatus.Sended:
            return "已发货";
        case OrderDeliveryStatus.Unable:
            return "无法发货";
        default:
            return "";
    }
}
// 售后类型
export function TranslateafterServiceType(status) {
    switch (status) {
        case AfterServiceType.All:
            return "全部";
        case AfterServiceType.Refund:
            return "退款";
        case AfterServiceType.ReturnGoods:
            return "退货";
        case AfterServiceType.ExchangeGoods:
            return "换货";
        default:
            return "";
    }
}
// 售后处理装填
export function TranslateafterServiceStatus(status) {
    switch (status) {
        case AfterServiceStatus.All:
            return "全部";
        case AfterServiceStatus.Pending:
            return "待处理";
        case AfterServiceStatus.Ing:
            return "进行中";
        case AfterServiceStatus.Pass:
            return "已通过";
        case AfterServiceStatus.Reject:
            return "已拒绝";
        case AfterServiceStatus.Closed:
            return "已关闭";
        case AfterServiceStatus.ReturnExchange:
            return "退换货";
        default:
            return "";
    }
}
// 售后列表 - 发货状态
export function TranslateShipmentStatus(status) {
    switch (status) {
        case ShipmentStatus.Pendding:
            return "待发货";
        case ShipmentStatus.Shippenf:
            return "已发货";
        case ShipmentStatus.Return:
            return "退货处理";
        case ShipmentStatus.Exchange:
            return "换货处理";
        case ShipmentStatus.Exchanged:
            return "已换货";
        case ShipmentStatus.Returned:
            return "已退货";
        case ShipmentStatus.RejectReturn:
            return "拒绝退货";
        case ShipmentStatus.RejectExchange:
            return "拒绝换货";
        case ShipmentStatus.NoShippingRequired:
            return "无需发货";
        default:
            return "";
    }
}
// 价格类型
export function TranslateEditPriceType(status) {
    switch (status) {
        case EditPriceType.price:
            return "price";
        case EditPriceType.stock:
            return "stock";
        case EditPriceType.originalPrice:
            return "originalPrice";
        case EditPriceType.selfSalePrice:
            return "selfSalePrice";
        default:
            return "";
    }
}
// 优惠券 - 批次状态
export function TranslateBranchStatus(type) {
    switch (type) {
        case BranchStatus.All:
            return "全部";
        case BranchStatus.Normal:
            return "正常";
        case BranchStatus.Creating:
            return "生成中";
        case BranchStatus.Stoped:
            return "已停用";
        case BranchStatus.LootedAll:
            return "已抢光";
        default:
            return "";
    }
}
// 优惠券 - 商品规则
export function TranslateCouponGoodsLimit(type) {
    switch (type) {
        case CouponGoodsLimit.Null:
            return "不限制";
        case CouponGoodsLimit.Goods:
            return "限制商品";
        case CouponGoodsLimit.Category:
            return "限制分类";
        case CouponGoodsLimit.Channel:
            return "限制频道";
        default:
            return "";
    }
}
export function TranslateCouponWriteOffStatus(type) {
    switch (type) {
        case CouponWriteOffStatus.All:
            return "全部";
        case CouponWriteOffStatus.Unassigned:
            return "未分配";
        case CouponWriteOffStatus.Assigned:
            return "已分配";
        case CouponWriteOffStatus.Used:
            return "已使用";
        default:
            return "";
    }
}
export function TranslateCouponAvailableStatus(type) {
    switch (type) {
        case CouponAvailableStatus.All:
            return "全部";
        case CouponAvailableStatus.Effective:
            return "有效";
        case CouponAvailableStatus.Invalid:
            return "失效";
        case CouponAvailableStatus.Expired:
            return "已过期";
        default:
            return "";
    }
}
export function TranslateCouponObtainWay(type) {
    switch (type) {
        case CouponObtainWay.Link:
            return "链接券";
        case CouponObtainWay.Couponcode:
            return "券码券";
        default:
            return "";
    }
}
export function TranslateProductType(type) {
    switch (type) {
        case ProductType.All:
            return "全部";
        case ProductType.entity:
            return "实物商品";
        case ProductType.virtual:
            return "虚拟商品";
        default:
            return "实物商品";
    }
}
export function TranslateLuckyMoneyStatus(type) {
    switch (type) {
        case LuckyMoneyStatus.All:
            return "全部";
        case LuckyMoneyStatus.UnStart:
            return "未开始";
        case LuckyMoneyStatus.Ing:
            return "进行中";
        case LuckyMoneyStatus.End:
            return "已结束";
        case LuckyMoneyStatus.Stop:
            return "已停用";
        default:
            return "";
    }
}
export function TranslateConsumotionStatus(type) {
    switch (type) {
        case ConsumotionStatus.All:
            return "全部";
        case ConsumotionStatus.NotConsumed:
            return "未消费";
        case ConsumotionStatus.Consumed:
            return "已消费";
        case ConsumotionStatus.Invalid:
            return "已过期";
        default:
            return "";
    }
}
export function TranslateShopAfterSaleStatus(type) {
    switch (type) {
        case ShopAfterSaleStatus.All:
            return "全部";
        case ShopAfterSaleStatus.RefundPending:
            return "退款-待处理";
        case ShopAfterSaleStatus.RefundBusinessClosed:
            return "退款-商家关闭";
        case ShopAfterSaleStatus.RefundAgree:
            return "退款-已同意";
        case ShopAfterSaleStatus.RefundOver:
            return "退款-已完成";
        case ShopAfterSaleStatus.RefundUserClosed:
            return "退款-用户关闭";
        case ShopAfterSaleStatus.RetrunRefundPending:
            return "退货退款-待处理";
        case ShopAfterSaleStatus.RetrunRefundBusinessClosed:
            return "退货退款-商家关闭";
        case ShopAfterSaleStatus.RetrunRefundBusinessFirstAgree:
            return "退货退款-商家同意";
        case ShopAfterSaleStatus.RetrunRefundUserSendBack:
            return "退货退款-用户回寄";
        case ShopAfterSaleStatus.RetrunRefundBusinessSecondAgree:
            return "退货退款-商家同意退款";
        case ShopAfterSaleStatus.RetrunRefundOver:
            return "退货退款-退款完成";
        case ShopAfterSaleStatus.RetrunRefundAutoClosed:
            return "退货退款-自动关闭";
        case ShopAfterSaleStatus.RetrunRefundUserClosed:
            return "退货退款-用户关闭";
        default:
            return "";
    }
}
export function FormatterText(row, col, cellValue) {
    return cellValue || '-';
}
//批量导入商品状态
export function TranslateBatchImportStatus(status) {
    switch (status) {
        case ImportGoodsStatus.Importing:
            return "导入中";
        case ImportGoodsStatus.Success:
            return "全部成功";
        case ImportGoodsStatus.SomeSuccess:
            return "部分成功";
        case ImportGoodsStatus.Failed:
            return "全部失败";
        default:
            return "";
    }
}
