import { ref } from "vue";
export class CountService {
    static GetTotalCount() {
        return this.WaitCount.value + this.ReturnCount.value;
    }
    static ChangeTipCount(num) {
        this.WaitCount.value = num.data.deliverNum; // 待发货数量
        this.AfterSaleWait.value = num.data.afterNum; // 售后待处理
    }
}
CountService.WaitCount = ref(0); // 代发货数量
CountService.ReturnCount = ref(0); // 老版运营操作的退货货数量
CountService.AfterSaleWait = ref(0); // 售后待处理
