import { defineComponent } from "vue";
import SubMenu from "./SubMenu.vue";
export default defineComponent({
    components: { SubMenu },
    emits: ["select"],
    props: {
        menus: {
            type: Object,
            required: true,
        },
        activeId: {
            type: String,
        },
        activeRoute: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const onSelected = (item) => {
            emit("select", item);
        };
        return { onSelected };
    },
});
