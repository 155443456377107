import Layout from '../views/Layout.vue';
export default {
    path: '/settings',
    name: 'settings',
    component: Layout,
    children: [
        {
            path: '',
            name: 'orderInfoIndex',
            component: () => import('views/Settings/OrderInfoManagement/Index.vue')
        },
        {
            path: "orderInfo",
            name: 'orderInfo',
            component: () => import('views/Settings/OrderInfoManagement/Index.vue')
        },
    ]
};
