import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
var UploadStatus;
(function (UploadStatus) {
    UploadStatus[UploadStatus["Loading"] = 0] = "Loading";
    UploadStatus[UploadStatus["Loaded"] = 1] = "Loaded";
})(UploadStatus || (UploadStatus = {}));
export default function UploadAction(props, context) {
    // showImageList 用来存储展示上传的图片列表
    const showImageList = reactive(new Array());
    const onExceed = (fileList) => {
        ElMessage.error("上传总数量不能大于" + props.limit);
    };
    const uploadDom = ref(null);
    const uploaded = (val, file, fileList) => {
        if (showImageList.length > props.limit) {
            return;
        }
        if (val.isSuccess) {
            const url = (file.response && file.response.resultData) ? file.response.resultData : file.url;
            for (let i = 0; i < showImageList.length; i++) {
                if (showImageList[i].uid == file.uid) {
                    showImageList[i].url = url;
                    showImageList[i].status = UploadStatus.Loaded;
                    break;
                }
            }
            context.emit('onAdd', val);
        }
        else {
            ElMessage.error(`图片${file.name}上传出错`);
            for (let i = 0; i < showImageList.length; i++) {
                if (showImageList[i].uid == file.uid) {
                    showImageList.splice(i, 1);
                    break;
                }
            }
        }
        const finishedCount = showImageList.filter(x => x.status == UploadStatus.Loaded).length;
        // 当最后一个上传完成后进行排序
        if (finishedCount == showImageList.length) {
            // 每次上传完后，把Upload组件中的filedlist清除掉，不然再次上传可能会出现数量超出的现象
            uploadDom.value.clearFiles();
            uploadedFlag = false;
        }
    };
    // 判断当前选择的文件是否上传完成
    let uploadedFlag = false;
    // 文件上传前，把文件先放进列表中，并把状态改成未上传完成状态
    const onProgress = (e, file, fileList) => {
        if (!uploadedFlag) {
            for (let i = 0; i < fileList.length; i++) {
                showImageList.push({ uid: fileList[i].uid, url: '', name: fileList[i].name, status: UploadStatus.Loading });
            }
        }
        uploadedFlag = true;
    };
    const onChanged = (file, fileList) => {
        console;
    };
    const remove = (index, val) => {
        ElMessageBox.confirm("是否删除该图片？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
        }).then(() => {
            showImageList.splice(index, 1);
            context.emit('onDel', val);
        });
    };
    const dialogVisible = ref(false);
    const dialogImageUrl = ref("");
    const preview = (index, img) => {
        previewAllFlag.value = false;
        dialogImageUrl.value = img.url;
        dialogVisible.value = true;
        context.emit('onPreview', img);
    };
    const beforeUpload = (val) => {
        if (uploadedFlag) {
            ElMessage.error("请先等待当前文件上传完成");
            return false;
        }
        if (props.onBeforeUpload)
            return props.onBeforeUpload(val);
        return true;
    };
    const previewAllFlag = ref(false);
    const previewAll = () => {
        previewAllFlag.value = true;
        dialogVisible.value = true;
    };
    const toLeft = (index, file) => {
        const temp = showImageList[index];
        showImageList.splice(index, 1);
        showImageList.splice(index - 1, 0, temp);
    };
    const toRight = (index, file) => {
        const temp = showImageList[index];
        showImageList.splice(index, 1);
        showImageList.splice(index + 1, 0, temp);
    };
    return {
        uploadDom,
        uploaded,
        remove,
        preview,
        beforeUpload,
        showImageList,
        dialogImageUrl,
        dialogVisible,
        previewAllFlag,
        previewAll,
        toLeft,
        toRight,
        onChanged,
        onExceed,
        onProgress
    };
}
