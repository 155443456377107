import { GetGoShopList } from "@/api/GoShopApi";
import { reactive } from "vue";
import SessionStoreService from "../Store/SessionStoreService";
import { Shop } from "./Shop";
import { FormatterDateTime } from "@/common/formatter";
export default class ShopService {
    static async InitAllShops(args) {
        if (this.shops.length > 0) {
            return this.shops;
        }
        const result = await GetGoShopList(args);
        if (result.isSuccess) {
            this.shops.splice(0, this.shops.length);
            for (let i = 0; i < result.resultData.list.length; i++) {
                this.shops.push(result.resultData.list[i]);
            }
        }
        return this.shops;
    }
    static async RefreshAllShops(args) {
        const result = await GetGoShopList(args);
        if (result.isSuccess) {
            this.shops.splice(0, this.shops.length);
            for (let i = 0; i < result.resultData.list.length; i++) {
                this.shops.push(result.resultData.list[i]);
            }
        }
        return this.shops;
    }
    static ResetCurrentShop() {
        this.currentShop.id = this.NoShopId;
    }
    static ClearCurrenShop() {
        SessionStoreService.remove('currentShop');
    }
    static async InitCurrentShop(id) {
        if (this.shops.length) {
            this.shops.forEach((i) => {
                if (i.id == id) {
                    this.SetObjToCurrrentShop(i);
                    SessionStoreService.set("currentShop", JSON.stringify(this.currentShop));
                }
            });
            return;
        }
    }
    static GetAllShop() {
        return this.shops;
    }
    static GetCurrentShop() {
        if (this.currentShop.id != this.NoShopId) {
            return this.currentShop;
        }
        const str = SessionStoreService.get("currentShop");
        if (str) {
            this.SetObjToCurrrentShop(JSON.parse(str));
        }
        else {
            this.ResetCurrentShop();
        }
        return this.currentShop;
    }
    static CurrentShopIsNull() {
        const str = SessionStoreService.get("currentShop");
        if (str) {
            this.SetObjToCurrrentShop(JSON.parse(str));
            return this.currentShop.id == this.NoShopId;
        }
        return true;
    }
    static SetObjToCurrrentShop(data) {
        this.currentShop.id = data.id;
        this.currentShop.storeStatus = data.storeStatus;
        this.currentShop.storeNoCanBeModified = data.storeNoCanBeModified;
        this.currentShop.merchantId = data.merchantId;
        this.currentShop.storeId = data.storeId;
        this.currentShop.name = data.name;
        this.currentShop.approveInfo = data.approveInfo;
        this.currentShop.openedTime = FormatterDateTime(data.openedTime, "yyyy-MM-dd");
        this.currentShop.storeNo = data.storeNo;
        this.currentShop.storeLogoPc = data.storeLogoPc;
        this.currentShop.storeLogoH5 = data.storeLogoH5;
        this.currentShop.storeUrl = data.storeUrl;
        this.currentShop.storeChannelList = data.storeChannelList;
    }
}
ShopService.shops = reactive(new Array());
ShopService.currentShop = reactive(new Shop());
ShopService.NoShopId = -1;
