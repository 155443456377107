import { reactive } from '@vue/reactivity';
/**
 * 面包屑管理
 */
export default class BreadCrumbService {
    /**
     * 获取面包屑列表
     * @returns 面包屑列表
     */
    static GetBreadCrumbs() {
        return this.breadCrumbs;
    }
    /**
     * 增加面包屑对象
     * @param item 面包屑对象
     */
    static InsertBreadCrumb(item) {
        this.breadCrumbs.push(item);
    }
    /**
     * 根据Id从列表中去除某个面包屑
     * @param id 要去除的面包屑id
     */
    static RemoveBreadCrumbById(id) {
        for (let i = 0; i < this.breadCrumbs.length; i++) {
            if (this.breadCrumbs[i].id == id) {
                this.breadCrumbs.splice(i, 1);
                break;
            }
        }
    }
    /**
     * 清空面包屑列表
     */
    static ClearBreadCrumb() {
        this.breadCrumbs.splice(0, this.breadCrumbs.length);
    }
}
BreadCrumbService.breadCrumbs = reactive(new Array());
