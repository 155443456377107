import { defineComponent, onMounted, ref } from "vue";
import { ShopStatus } from "@/common/enum";
import { copy, shopUrl } from "@/common/utils";
import { ElMessage, ElMessageBox } from "element-plus";
export default defineComponent({
    emits: ["click", "closed", "reopen"],
    props: {
        shopInfo: {
            type: Object,
            required: false,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    setup(props, { emit }) {
        onMounted(() => {
            if ([
                ShopStatus.Saleing,
                ShopStatus.SaleVerifying,
                ShopStatus.SaleVerifyReject,
            ].indexOf(props.shopInfo.storeStatus) > -1) {
                props.shopInfo.storeChannelList &&
                    props.shopInfo.storeChannelList.unshift({ name: "默认" });
            }
        });
        const curIndex = ref(0);
        const shopStatusObj = {
            [ShopStatus.NoSaleVerifying]: {
                name: "未开店-审核中",
                btn: "（审核中）",
                tip: "待开业",
                class: "beopen",
            },
            [ShopStatus.NoSaleVerifyReject]: {
                name: "未开店-审核拒绝",
                btn: "（信息审核未通过）",
                tip: "待开业",
                class: "beopen",
            },
            [ShopStatus.Canceled]: {
                name: "未开店-已撤销",
                btn: "",
                tip: "待开业",
                class: "beopen",
            },
            [ShopStatus.Saleing]: {
                name: "营业中",
                btn: "",
                tip: "营业中",
                class: "open",
            },
            [ShopStatus.SaleVerifying]: {
                name: "已开店-审核中",
                btn: "（审核中）",
                tip: "营业中",
                class: "open",
            },
            [ShopStatus.SaleVerifyReject]: {
                name: "已开店-审核拒绝",
                btn: "（信息审核未通过）",
                tip: "营业中",
                class: "open",
            },
            [ShopStatus.Close]: {
                name: "已关闭",
                btn: "（重新营业）",
                tip: "已关闭",
                class: "close",
            },
        };
        const handleToshop = (item) => {
            emit("click", item);
        };
        const handleClosed = (item) => {
            ElMessageBox.confirm("是否停止营业？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                callback: (res) => {
                    if (res == "cancel") {
                        return ElMessage({
                            type: "info",
                            message: "已取消",
                        });
                    }
                    emit("closed", item);
                },
            });
        };
        const handleReopen = (item) => {
            emit("reopen", item);
        };
        const enter = (index) => {
            curIndex.value = index;
        };
        const leave = () => {
            curIndex.value = 0;
        };
        const openShop = (info) => {
            let url = "";
            url = setShopChannel(info);
            let el = document.createElement("a");
            document.body.appendChild(el);
            el.href = "//" + url;
            el.target = "_blank";
            el.click();
            document.body.removeChild(el);
        };
        const setShopChannel = (info) => {
            return `${props.shopInfo.storeNo}.${shopUrl()}${info.channelNo ? `?from=${info.channelNo}` : ""}`;
        };
        return {
            curIndex,
            ShopStatus,
            shopStatusObj,
            handleToshop,
            handleClosed,
            handleReopen,
            enter,
            leave,
            copy,
            openShop,
            setShopChannel,
        };
    },
});
