import { defineAsyncComponent, reactive, ref, h, markRaw } from "vue";
import { getDeliveryCount } from "@/api/LogisticApi";
import eventBus from "@/utils/eventBus";
import { CountService } from "@/services/Logistic/CountService";
import { cancelCreateShop, changeShop, closeOpenDrawer, createShop, onOpenDrawer, onTipsCountChanged, openMerchantInfoDialog, receiveNewImportMessage, DealImportMessage, showCreateShop, UpdateUnreadNum, createShopSuccess } from "@/utils/eventBusConst";
import ShopService from "@/services/Shop/ShopService";
import { MessageRead, UnreadImportMessageList } from "@/api/MessageApi";
import { ElNotification } from "element-plus";
import MsgNotification from "@/components/msgNotification/index.vue";
import router from "@/router";
import { WindowOpen } from "@/common/utils";
export default function LayOut() {
    const showDrawn = ref(false);
    const showSelectShopDialog = ref(false);
    const showSelectShoDialogpCloseAble = ref(true);
    const showMerchantInfoDialog = ref(false);
    const createShopDialog = ref(false);
    const curDrawnComponent = ref("");
    const notificationDlg = ref(null);
    const shopList = ShopService.GetAllShop();
    const showNoShopDialog = ref(false);
    const unreadImportMsgList = reactive(new Array());
    const draswerComponents = markRaw({
        msgList: defineAsyncComponent(() => import("@/views/Message/Index.vue")),
    });
    eventBus.on(onTipsCountChanged, () => {
        getDeliveryCount().then(res => {
            CountService.ChangeTipCount(res);
        });
    });
    eventBus.on(onOpenDrawer, (msgObj) => {
        showDrawn.value = true;
        curDrawnComponent.value = msgObj.componentName;
    });
    eventBus.on(closeOpenDrawer, () => {
        showDrawn.value = false;
        curDrawnComponent.value = '';
    });
    eventBus.on(changeShop, (args) => {
        showSelectShopDialog.value = true;
        showSelectShoDialogpCloseAble.value = args?.closeAble;
    });
    eventBus.on(createShop, () => {
        createShopDialog.value = true;
    });
    eventBus.on(cancelCreateShop, () => {
        createShopDialog.value = false;
    });
    eventBus.on(createShopSuccess, () => {
        createShopDialog.value = false;
        const shops = ShopService.GetAllShop();
        if (shops.length <= 0) {
            window.location.reload();
        }
    });
    eventBus.on(openMerchantInfoDialog, () => {
        showMerchantInfoDialog.value = true;
    });
    const showReceiveNewImportMessage = ref(false);
    const showMsgNotification = () => {
        notificationDlg.value = ElNotification({
            dangerouslyUseHTMLString: true,
            message: h(MsgNotification, { 'importMsgList': unreadImportMsgList }),
            duration: 0,
            position: "top-right",
            onClose: () => {
                showReceiveNewImportMessage.value = false;
            },
            offset: 60,
        });
    };
    eventBus.on(receiveNewImportMessage, (msgObj) => {
        if (msgObj && msgObj.upload) {
            unreadImportMsgList.splice(0, unreadImportMsgList.length);
        }
        getUnreadImportMessage();
    });
    eventBus.on(DealImportMessage, (messageId) => {
        MessageRead({ messageId, storeId: ShopService.GetCurrentShop().id }).then((res) => {
            eventBus.emit(UpdateUnreadNum);
            eventBus.emit(receiveNewImportMessage);
            const routeUrl = router.resolve({
                path: '/goods',
                query: {
                    productNo: unreadImportMsgList[0]?.content?.productNo
                }
            });
            WindowOpen(routeUrl.href);
            unreadImportMsgList.splice(0, 1);
            if (unreadImportMsgList?.length == 0) {
                notificationDlg.value && notificationDlg.value.close();
            }
        });
    });
    eventBus.on(showCreateShop, () => {
        showNoShopDialog.value = true;
    });
    const getUnreadImportMessage = () => {
        UnreadImportMessageList(ShopService.GetCurrentShop().id).then((res) => {
            if (res.resultData.length == 0) {
                notificationDlg.value && notificationDlg.value.close();
                showReceiveNewImportMessage.value = false;
            }
            res.resultData.forEach((i) => {
                i.content = JSON.parse(i.content);
                if (!(unreadImportMsgList.find((list) => { return list.id == i.id; }))) {
                    unreadImportMsgList.unshift(i);
                }
            });
            if (!showReceiveNewImportMessage.value && unreadImportMsgList.length > 0) {
                showReceiveNewImportMessage.value = true;
                showMsgNotification();
            }
        });
    };
    const UnreadImportMessageTimer = () => {
        setInterval(() => { getUnreadImportMessage(); }, 300000);
    };
    return {
        showDrawn,
        showSelectShopDialog,
        showSelectShoDialogpCloseAble,
        showMerchantInfoDialog,
        createShopDialog,
        curDrawnComponent,
        shopList,
        showNoShopDialog,
        draswerComponents,
        getUnreadImportMessage,
        UnreadImportMessageTimer,
        showMsgNotification,
    };
}
