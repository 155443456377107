export default class SessionStoreService {
    static get(key) {
        if (!key) {
            return "";
        }
        return sessionStorage.getItem(key) || "";
    }
    static set(key, value) {
        if (!key || !value) {
            return;
        }
        sessionStorage.setItem(key, value);
    }
    static getObj(key) {
        const string = sessionStorage.getItem(key);
        if (string) {
            return JSON.parse(string);
        }
        return null;
    }
    static remove(key) {
        if (!key) {
            return;
        }
        sessionStorage.removeItem(key);
    }
}
SessionStoreService.getObj("start_time");
