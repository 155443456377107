import { ElMessage, ElMessageBox } from "element-plus";
import { ConfirmGOShopName, ConfirmGOShopNo } from "@/api//GoShopApi";
export function GetImageSize(file) {
    return new Promise((reslove, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result?.toString();
            //加载图片获取图片真实宽度和高度
            const image = new Image();
            image.onload = function () {
                const width = image.width;
                const height = image.height;
                reslove({ width: width, height: height });
            };
            image.onerror = function (err) {
                reject(err);
            };
            image.src = data || '';
        };
        reader.readAsDataURL(file);
    });
}
export function ScrollToTop() {
    const main = document.querySelector('.el-main');
    if (main) {
        main.scrollTo(0, 0);
    }
}
export function beforeToday(time) {
    const nowDate = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
    const oneYearLater = new Date(nowDate.getTime() + oneDay * 365);
    return !(time.getTime() < nowDate || time.getTime() > oneYearLater);
}
export function copy(content) {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', content);
    input.select();
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        ElMessage.success('复制成功');
    }
    document.body.removeChild(input);
}
export function shopUrl() {
    return process.env.NODE_ENV === 'production'
        ? 'shop.6360.cn'
        : 'tshop.6360.cn';
}
export function confirm(con, showCancelButton, tit) {
    return ElMessageBox.confirm(con, tit || '提醒', {
        showCancelButton: showCancelButton,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    });
}
export function CheckShopName(event, oldValue) {
    if (!event)
        return;
    if (oldValue != event)
        ConfirmGOShopName(event).then((res) => {
            if (!res.isSuccess)
                ElMessage.warning(`店铺名称${res.resultMsg}`);
        });
}
export function CheckStoreNo(event) {
    if (!event)
        return;
    ConfirmGOShopNo(event).then((res) => {
        if (!res.isSuccess)
            ElMessage.warning(`店铺标识${res.resultMsg}`);
    });
}
export function WindowOpen(url) {
    const dom = document.createElement('a');
    dom.setAttribute("id", "openlink");
    dom.setAttribute('target', '_blank');
    dom.setAttribute('href', url);
    document.body.appendChild(dom);
    dom.click();
    document.getElementById("openlink")?.remove();
}
